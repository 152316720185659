import React from 'react';

const DietaryIcons = ({icons, style, iconSize = 24}) => {
  return icons.length ?
    <div className="dietary-icons" style={{ display: 'flex', alignItems: 'flex-start', gap: 8, marginTop: '4px', ...(style && style) }}>
      {
        icons.map((option, idx) =>
          <img
            key={option + idx}
            role={`icon-${option}`}
            style={{height: iconSize}}
            src={require(`../img/icons/dietary/${option}.svg`)}
          />)
      }
    </div>
    :
    null;
};
export default DietaryIcons;