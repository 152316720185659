import React from 'react';
import Cross from '../shared/Cross';

const TemporarilyClosedModal = ({ onClose, isDelivery, restaurant, inApp, warning }) => {
  const now = new Date();
  if (isDelivery && !restaurant?.isDeliveryTempClosed && restaurant?.openingTime < `${now.getHours()}:${now.getMinutes()}` || !isDelivery && !restaurant?.isCollectionTempClosed) {
    return (
      <div className="filter-modal is-text-center is-relative is-flex is-justify-content-center pt-6 pb-4">
        <div className='content w100'>
        {inApp
          ? <img src={warning?.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
          : <Cross topPosition={0} rightPosition={0} absolute onClick={onClose} />
        }
          <h3 className="title is-3">CLOSED</h3>
          <div className="block">
            <p>
              Dough! This restaurant is closed for today. Please search for another restaurant.
            </p>
          </div>
          <div className="block pt-4">
            <button
              id="confirm"
              className="btn btn-primary"
              onClick={onClose}
            >
              Search again
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`filter-modal is-text-center is-relative is-justify-content-center  ${inApp ? '' : " pb-4pt-6 is-flex"}`}
      style={inApp ? { textAlign: 'center' } : {}}
    >
      {inApp
        ? <img src={warning?.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
        : <Cross topPosition={0} rightPosition={0} absolute onClick={onClose} />
      }

      <div className='content w100'>
        <h3
          className="title is-3"
          style={{
            marginTop: inApp ? 16 : 60,
            fontSize: inApp ? 32 : '',
            marginBottom: 16,
            lineHeight: '90%',
            textTransform: inApp && 'uppercase'
          }}
        >
          {isDelivery ? 'We\'re Sorry!' : 'Temporarily Closed'}
        </h3>
        <div className="block">
          <p>
            {isDelivery
              ? 'Unfortunately Uber can no longer fulfil this order. Please try again or try Collection instead.'
              : 'Dough! This restaurant is temporarily closed. Please search for another restaurant.'
            }
          </p>
        </div>
        <div className="block pt-4">
          <button
            id="confirm"
            className="btn btn-primary w100"
            onClick={onClose}
            style={inApp ? { maxWidth: '12rem', marginBottom: 8 } : { marginBottom: 16 }}
          >
            {isDelivery ? 'Take me back' : 'Search again'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemporarilyClosedModal;