import { useRef, useEffect, useState } from 'react';

export const useIsMounted = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }; 
  }, []);

  return isMounted;
};

export const useSafeState = (initialValue) => {
  const [ value, setter ] = useState(initialValue);

  const isMounted = useIsMounted();

  const safeSetter = (value) => {
    if(isMounted.current) {
      setter(value);
    }
  };

  return [ value, safeSetter ];
};
