export const pathMW = (url, isDelivery) => isDelivery ? `/delivery${url}` : `/collection${url}`;

export const historyMW = {
  push: (url, isDelivery, history, state) => {
    validateCookiesForJourney(isDelivery ? 'Delivery' : 'ClickAndCollect');
    return isDelivery ? history.push(`/delivery${url}`, state) : history.push(`/collection${url}`, state);
  },
  replace: (url, isDelivery, history, state) => {
    validateCookiesForJourney(isDelivery ? 'Delivery' : 'ClickAndCollect');
    return isDelivery ? history.replace(`/delivery${url}`, state) : history.replace(`/collection${url}`, state);
  }
};

const validateCookiesForJourney = (journey) => {
  document.cookie = `JOURNEY = ${journey}; expires = Session`;
};