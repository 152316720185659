import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const useSendToHome = (isDeliveryOverride) => {
  const {deliveryLandingPage} = useSelector(state => state.session.features);
  const { isDelivery } = useSelector(state => state.basket);
  const history = useHistory();
  if (!deliveryLandingPage && isDelivery) {
    return () => {
      window.location.href = 'https://pizzaexpress.com/delivery';
    };
  } else {
    return () => {
      history.push(isDelivery || isDeliveryOverride ? '/delivery' : '/collection');
    };
  }
};