import React, { Fragment } from 'react';
import chevronDown from '../img/icons/chevronDownWhite.svg';

export const DiscountSummary = ({ isOpen, setIsOpen, discounts, subTotal, filteredDiscounts }) => {
    const collatedDiscounts = discounts?.reduce((acc, cur) => {
        const existingDiscount = acc[cur.offerTitle];
        if (existingDiscount) {
            acc[cur.offerTitle] = {
                ...existingDiscount,
                quantity: existingDiscount.quantity + cur.quantity,
                amount: existingDiscount.amount + cur.amount
            };
        } else {
            acc[cur.offerTitle] = cur;
        }
        return acc;
    }, {});

    return (
        <div
            style={{
                color: 'white',
                fontSize: 18,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 24
            }}
        >
            <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h4 style={{ fontFamily: 'dm-sans-bold' }}>{Object.keys(collatedDiscounts)?.length} Reward{Object.keys(collatedDiscounts)?.length > 1 ? 's' : ''} added</h4>
                <img
                    src={chevronDown}
                    style={{ transform: !isOpen ? 'rotate(180deg)' : null }}
                />
            </div>

            {
                isOpen &&
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 30px minmax(70px, auto)', gridTemplateRows: 'auto', maxWidth: 400, gap: '8px 10px' }}>
                    {
                        Object.values(collatedDiscounts)?.map((discount, idx) =>
                            <Fragment key={idx}>
                                <p style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{discount?.offerTitle.replaceAll('#', ' ')}</p>
                                <p style={{ margin: '0 auto' }}>{discount?.quantity}</p>
                                <p>{(discount?.amount > 0 && discount.offerTitle !== 'Free Delivery') ? `£${discount?.amount?.toFixed(2)}` : ''}</p>
                            </Fragment>
                        )
                    }
                </div>
            }
            {
                subTotal < 10 && <p>Basket total under £10 by £{(10 - subTotal).toFixed(2)}. You need to spend a minimum of £10 to redeem your rewards</p>
            }
        </div>
    )
};