import api from './api';

export const applicationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationState: builder.query({
      query: (restaurantId) => restaurantId ? `application/${restaurantId}` : 'application',
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicationStateQuery,
  useLazyGetApplicationStateQuery,
} = applicationApi;