import React from 'react';

const LoyaltyTierHeader = ({ tierName, rewardData, inApp }) => {
  return (
    <div className="tier-header-wrapper" style={{ paddingBottom: inApp ? '20px' : null }}>
      <h2 className='title loyalty-tier-header'>{tierName.toUpperCase()}</h2>
      {!rewardData && <div className="block tier-name loyalty-tier-subtitle mx-5 is-text-center">Earn pizza stamps to unlock rewards</div>}
    </div>
  );
};

export default LoyaltyTierHeader;
