import React from 'react';
import Close from '../../img/icons/close.svg';

const Cross = ({ onClick, topPosition, rightPosition, absolute }) => {
  const position = {
    top: topPosition ? topPosition : 0,
    right: rightPosition ? rightPosition : 0,
    position: 'absolute'
  };
  
  return (
    <div
      className={'icon is-medium is-pointer'}
      style={{
        ...(absolute && position),
        width: '48px',
        height:'48px'
      }}
      onClick={onClick}
    >
      <img width="48px" height="48px" src={Close} alt="Close button" />
    </div>
  );
};

export default Cross;
