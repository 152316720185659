import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import basketReducer from './basketSlice';
import menuReducer from './menuSlice';
import sessionReducer from './sessionSlice';
import loyaltyReducer from './loyaltySlice';
import api from '../services/api';

export default function configureStore(history, initialState) {
  const middleware = [
    api.middleware,
    thunk,
    routerMiddleware(history),
  ];

  const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    router: connectRouter(history),
    basket: basketReducer,
    menu: menuReducer,
    session: sessionReducer,
    loyalty: loyaltyReducer,
  });

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : window;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
