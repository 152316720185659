import { useEffect } from 'react';

const useBodyScrollLock = (lock) => {
  useEffect(() => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (body) body.style.overflow = lock ? 'hidden' : 'auto';
    if (html) html.style.overflow = lock ? 'hidden' : 'auto';
    document.body.style.overflow = lock ? 'hidden' : 'auto';
    return () => {
      if (body) body.style.overflow = 'auto';
      if (html) html.style.overflow = 'auto';
      document.body.style = 'auto';
    };
  }, [lock]);
};

export default useBodyScrollLock;
