import React from 'react';
import Cross from '../shared/Cross';

const DeliveryErrorModal = ({ onClose, inApp, warning }) => {
  return (
    <div 
    className={`filter-modal  ${inApp? '' : "pt-4 pb-5"}`}
    style={inApp ? {textAlign:'center'}:{}}
    >
      {inApp ?
        <img src={warning.src} style={{ width: 120, margin: '1.5rem'}} />
        : <Cross onClick={onClose} absolute rightPosition={7} topPosition={7} />
      }
      <div className="content w100">
        <h3 style={{ marginTop: inApp? 16 : 60, marginBottom: 0, lineHeight: '90%' }} className="title is-4">Error</h3>
        <div className="block">
          <p style={{margin: '1.5rem 0.7rem'}}>
            A rider is no longer available for your desired location. Please enter a new postcode or order for Collection
          </p>
        </div>
        <div className="block">
          <button
            id="confirm"
            className="btn btn-primary w100"
            onClick={onClose}
            style={inApp ? {maxWidth:'12rem', marginBottom: 8}:{}}
          >
            Select new location
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryErrorModal;