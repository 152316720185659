import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBannerHeight } from '../store/sessionSlice';
import { useScrollAnimationController } from '../hooks/useScrollAnimationController';

const PopupBanner = ({ children, hideCheckout, isMenu, justifyEnd, parentRef, skipAnimation }) => {
  const {inApp, bannerHeight, appInsets} = useSelector(state => state.session);
  const [bannerRef, setBannerRef] = useState(false);
  const dispatch = useDispatch();

  const shouldAnimate = useScrollAnimationController(parentRef.current, bannerRef, skipAnimation);
  
  if(inApp && bannerRef?.offsetHeight && bannerHeight !== bannerRef?.offsetHeight) {
    dispatch(setBannerHeight(bannerRef?.offsetHeight));
  }

  return (
    <div
      ref={ref => setBannerRef(ref)}
      className={`popup-banner ${isMenu ? 'isMenu' : ''}`}
      style={{
        backgroundColor: shouldAnimate ? 'rgb(28 26 26)' : 'rgb(28 26 26 / 88%)',
        paddingBottom: appInsets?.bottom ? appInsets.bottom : null
      }}
    >
      {!hideCheckout && (
        <div className={`popup-banner-container ${justifyEnd ? 'justify-end' : ''}`}>
          {children}
        </div>)}
    </div>
  );
};

export default PopupBanner;

