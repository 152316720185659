import React, { useRef } from 'react';
import errorIcon from './../img/icons/error.svg';
import closeError from './../img/icons/clear.svg';
import { config } from '../config';

const brand = config.BRAND;

const InputField = ({ title, optionalTitle, description, name, value, valueCallback, error, errorCallback, valueType, maxLength, required, disableChecks, type = 'text', placeHolder, maxWidth }) => {
  const inputRef = useRef({ current: {} });

  let email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let tel = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  let cvv = /^[0-9]{3,4}$/;
  let postcode = brand === 'PE'
    ? /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/
    : /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/;

  const updateValue = (val) => {
    let value = val;
    if (maxLength) {
      value = val.slice(0, maxLength);
    }

    if (valueType === 'tel' || valueType === 'cvv' || valueType === 'cardNumber') {
      if (!isNaN(value)) {
        valueCallback(name, value);
      }
    } else if (valueType === 'text') {
      if (/^[\u00c0-\u01ffa-zA-Z- ']+$/.test(value) || value === '') {
        valueCallback(name, value);
      }
    } else {
      valueCallback(name, value);
    }
  };

  const isValidation = (value, reg) => {
    if (required && (!value || value === '') && !disableChecks) {
      errorCallback(name, `Please enter a valid ${optionalTitle || title.toLowerCase()}`);
    } else if (reg && !reg.test(value) && !disableChecks) {
      errorCallback(name, `Please enter a valid ${optionalTitle || title.toLowerCase()}`);
    } else {
      errorCallback(name, '');
    }
  };

  const validateValue = (value) => {
    if (valueType === 'email') {
      isValidation(value, email);
    } else if (valueType === 'tel') {
      isValidation(value, tel);
    } else if (valueType === 'cvv') {
      isValidation(value, cvv);
    } else if (valueType === 'postcode') {
      isValidation(value?.replace(' ', '') ?? '', postcode);
    } else {
      isValidation(value);
    }
  };

  const handleClear = () => {
    valueCallback(name, '');
    errorCallback(name, '');
    inputRef?.current?.focus();
  };

  return (
    <div key={name} className={`block is-flex is-flex-direction-column is-align-items-center input-field-container ${maxWidth ? 'max-width' : ''}`}>
      <p className="label">{title} {required ? '*' : ''}</p>
      {description && <p className='input-description'>{description}</p>}
      <div className="has-addons" style={{ width: '100%' }}>
        <div className={`${error ? 'has-warning' : ''}`} style={{ position: 'relative', width: '100%' }}>
          <input
            id={name + 'InnerInput'}
            value={value}
            onChange={({ target: { value } }) => updateValue(value)}
            onBlur={({ target: { value } }) => validateValue(value)}
            className={`input-field ${value ? 'is-active' : ''}  ${error ? 'error' : ''}`}
            type={type}
            ref={inputRef}
            placeholder={placeHolder}
          />
          {error && <i style={{ zIndex: 100, cursor: 'pointer' }} onClick={handleClear} className="icon is-medium is-control">
            <img src={closeError} alt="closeError" />
          </i>}
        </div>
      </div>
      {error && error !== ' ' ? (
        <div className="input-error-container">
          <img id={name + 'InnerInputError'} style={{ width: 20 }} src={errorIcon} alt="error" />
          <div className='error-message'>{error}</div>
        </div>
      ) : <div />}
    </div>
  );
};

export default InputField;
