import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddVoucherMutation } from '../../services/basket.api';
import { setHasShownEmployeeModal } from '../../store/sessionSlice';
import Cross from '../shared/Cross';


const EmployeeDiscountModal = ({ onClose, closeTopPosition, closeRightPosition, inApp, warning }) => {
  const { customer } = useSelector(state => state.session);
  const dispatch = useDispatch();
  const [addVoucher] = useAddVoucherMutation();

  const add = async () => {
    await addVoucher({ voucherCode: customer.employeeDiscountCode });
    dispatch(setHasShownEmployeeModal(true));
    onClose();
  };

  const dontAdd = () => {
    dispatch(setHasShownEmployeeModal(true));
    onClose();
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {inApp
        ? <img src={warning.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
        : <Cross onClick={onClose} topPosition={closeTopPosition} rightPosition={closeRightPosition} absolute />
      }
      <h2 style={{ marginTop: 60 }} className="title is-4">Employee Discount</h2>
      <p style={{ margin: 24 }}>Do you want to apply your Employee Discount 50%?</p>
      <div style={{display: 'flex', gap: "0 16px", flexWrap: 'wrap', justifyContent: 'center'}}>
        <button style={{ marginBottom: 24, width: '12rem' }} onClick={add} className="btn btn-primary">Yes</button>
        <button style={{ marginBottom: 24 }} onClick={dontAdd} className="btn btn-secondary">Not now</button>
      </div>
    </div>
  );
};

export default EmployeeDiscountModal;
