import React, { Fragment } from 'react';

const LoyaltyText = ({ text }) => {
  const components = text.split('#');
  return components.map((text, i) => {
    return (<Fragment key={i}>
      {text}{i < components.length - 1 ? <br /> : null}
    </Fragment>);
  });

};

export default LoyaltyText;