import React from 'react';
import LoyaltyTicketCard from './LoyaltyTicketCard';
import Carousel, { CarouselItem } from '../Carousel';

const LoyaltySliderContent = ({ data, disabled, onTicketClick, colourInverted, tierName, rewardsInfo, inApp, updating }) => (
  <section className="section is-small no-section-gutters no-top-padding" style={{ paddingBottom: inApp ? 0 : 24 }}>
    {!!data?.sections?.length &&
      <Carousel
        hasOffsetMobile={false}
        autoplay={false}
        allowReduceForSmallerScreens={true}
        fullWidth={false}
        centerButtons
        colourInverted={colourInverted}
        showCounter
        hideIndicators
        updating={updating}
      >
        {data?.sections?.map((value, i) => {
          let subHeading = null;
          if (value.maxSelectionForReward > 1) {
            subHeading = value.referAFriend ? `Pick up to ${value.maxSelectionForReward} below` : `Pick ${value.maxSelectionForReward} below`;
          }

          return (<CarouselItem key={`${i}`}>
            <LoyaltyTicketCard
              hasOffsetMobile={true}
              isDisabled={disabled}
              updating={updating}
              key={`card-${i}`}
              subHeading={subHeading}
              data={value}
              onTicketClick={onTicketClick}
              tierName={tierName}
              rewardsInfo={rewardsInfo}
              sectionIndex={i}
            />
          </CarouselItem>);
        })}
      </Carousel>}
  </section>
);

export default LoyaltySliderContent;
