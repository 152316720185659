import React, { useEffect, useState } from 'react';
import Close from '../../img/icons/close.svg';
import locked from './../../img/loyalty/lock.svg';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const getMonth = date => months[date.getMonth()];

export const dayNTH = d => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};


export const formatDateString = (date, abrv) => {
  if (abrv) {
    let day = date.getDate();
    return day + dayNTH(day) + ' ' + getMonth(date) + ' ' + date.getFullYear();
  } else {
    return date.getDate() + ' ' + getMonth(date) + ' ' + date.getFullYear();
  }
};

export const getValidityText = (lock, reward, offer) => {
  let validityText = reward?.rewardDetails?.validityText;
  if (lock && reward?.rewardDetails?.lockedValidityTextExpiryDate) {
    validityText = reward?.rewardDetails?.lockedValidityTextExpiryDate;
  }
  if (validityText) {
    validityText = validityText
      .replace('{{VOUCHER_EXPIRY}}', formatDateString(new Date(reward.validTo)))
      .replace('{{VOUCHER_ISSUANCE}}', formatDateString(new Date(reward.validFrom)));
  }
  return validityText || '';
};

export const getValidityTextComponents = (validityText) => {
  const validityTextWords = validityText.split(' ');
  const validityTextComponents = [];
  let inLink = false;
  let link = '';

  validityTextWords.forEach((word) => {
    if (word.startsWith('***')) {
      inLink = true;
    }
    if (inLink) {
      link += word.replace('***', '') + ' ';
      if (word.endsWith('***')) {
        inLink = false;
        validityTextComponents.push({ link: link.trimEnd() });
      }
    } else {
      validityTextComponents.push({ word });
    }
  });
  return validityTextComponents;
};

const LoyaltyModal = ({ onClose, reward, loyaltyRewardImage, showTermsAndConditions, inApp, isOffer }) => {
  const [validityText, setValidityText] = useState([]);
  const [slideOut, setSlideOut] = useState(false);
  const tierName = reward?.tierName === 'signUp' ? 'club member' : reward?.tierName;
  useEffect(() => {
    setValidityText(getValidityTextComponents(getValidityText(reward?.isLocked, reward)));
  }, [reward]);

  const onSlideOut = () => {
    if (inApp) return;
    setSlideOut(true);
    setTimeout(onClose, 250);
  };

  return (
    <>
      {reward ? <>
        <div className={`loyalty-modal loyalty-modal-background is-relative ${reward.disabled ? 'is-disabled' : ''} ${reward.isLocked ? 'is-locked' : ''}  ${reward.rewardDetails.colourOfTilenbwConverted.toLowerCase()} ${slideOut ? 'slideOut' : ''}`}>
          {reward.isLocked && <div className="is-locked-icon is-absolute zi-3" style={{ width: 48, height: 48, top: 24 }}>
            <div className="icon-wrapper is-justify-content-center is-align-items-center" style={{ width: 48, height: 48 }}>
              <img alt="locked" src={locked} style={{ width: 46, height: 46 }} />
            </div>
          </div>}
          <div className="close-icon-wrapper hide-on-mobile" >
            <i className={`icon is-large ${reward.rewardDetails.nbwRewardTheme === 'White' ? 'invert' : ''}`} style={{ cursor: 'pointer' }} >
              <img src={Close} alt="close" onClick={onClose} />
            </i>
          </div>
          {
            !inApp &&
            <div className={`mobile-drag-bar-container  ${reward.rewardDetails.nbwRewardTheme === 'White' ? 'invert' : ''}`} onTouchMove={onSlideOut}>
              <div className='mobile-drag-bar'></div>
            </div>
          }
          <div className="loyalty-content">
            <div className="loyalty-modal-top-content zi-2">
              <div className="loyalty-modal-header">
                <div className="header-text">{isOffer ? 'EXCLUSIVE OFFER' :
                reward.rewardDetails.showTierName ? tierName.toUpperCase() + ' REWARD' : reward.rewardDetails.rewardDetailPageHeaderCopy}</div>
              </div>
              {loyaltyRewardImage && <div className="main-image">
                <img
                  className={reward.rewardDetails.nbwRewardTheme === 'White' ? 'invert' : ''}
                  src={require(`./../../img/loyalty/modal/${loyaltyRewardImage.image}.svg`)}
                  alt="close"
                />
              </div>}
              <div className="title">
                {reward.rewardDetails.rewardTitle.replace(/#/g, ' ').toUpperCase()}
              </div>
              <div className="text-content">
                {reward.rewardDetails.rewardDetailPageDescription}
              </div>
            </div>
            <div style={{ margin: '16px 24px', height: 1, width: 'calc(100% - 48px)', backgroundColor: reward.rewardDetails.nbwRewardTheme === 'White' ? 'white' : '#1C1A1A' }}></div>
            <div className="loyalty-modal-bottom-content" style={{ paddingBottom: inApp && 0, zIndex: 2, position: 'relative' }}>
              {<div>{validityText.map((component, idx) => {
                if (component.link) return <span key={idx}><a style={{ color: reward.rewardDetails.nbwRewardTheme === 'White' ? 'white' : '#1C1A1A' }} onClick={showTermsAndConditions}>{component.link}</a>{' '}</span>;
                if (component.word) return <React.Fragment key={idx}>{component.word}{' '}</React.Fragment>;
              })}</div>}
            </div>
          </div>
        </div>
      </> : null}
    </>

  );
};

export default LoyaltyModal;
