import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import InputField from '../../../components/InputField';
import Layout from '../../../components/Layout';
import { useValidate2FAMutation, useRequest2FAMutation } from '../../../services/customer.api';
import LoaderWhite from '../../../img/common/Loader_360x360_White.gif';

const VerifyEmail = ({ onContinue }) => {
  const { restaurantId: restaurantIdParam, email, code } = useParams();
  const restaurantId = useSelector(state => state.session.restaurantId);
  const [inputValues, setInputValues] = useState({ verificationCode: '' });
  const [inputErrors, setInputErrors] = useState({ verificationCode: '' });
  const [processing, setProcessing] = useState(false);
  const { isDelivery } = useSelector(state => state.basket);
  const [confirmRequested, setConfirmRequested] = useState(false);
  const [request2FA] = useRequest2FAMutation();
  const [validate2FA] = useValidate2FAMutation();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const customer = useSelector(state => state.session.customer);
  const checkoutCompletePath = useSelector(state => state.session.checkoutCompletePath);
  const { inApp } = useSelector(state => state.session);

  const sendEmail = async () => {

    const res = await request2FA({
      emailAddress: customer?.customerSessionToken?.emailAddress || email,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      channel: isDelivery ? 'delivery' : 'collection',
      restaurantId,
    });
    if (res?.data?.success) {
      setConfirmRequested(true);
      setTimeout(() => setConfirmRequested(false), 30000);
    }
  };

  useEffect(() => {
    if (email && code) {
      setInputValues({ ...inputValues, verificationCode: code, emailAddress: email });
    } else {
      sendEmail();
    }
  }, []);

  const handleChange = useCallback((name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const handleValidate = async () => {
    setProcessing(true);
    if (inputValues.verificationCode === '' || inputErrors.verificationCode !== '') {
      setInputErrors({ verificationCode: 'Please enter a valid verification code' });
    } else {
      setButtonDisabled(true);
      const requestBody = {
        restaurantId: restaurantId || restaurantIdParam,
        emailAddress: inputValues.emailAddress && inputValues.emailAddress.length > 0
          ? inputValues.emailAddress
          : customer?.customerSessionToken?.emailAddress || email,
        code: inputValues.verificationCode,
        redirectUrl: checkoutCompletePath && checkoutCompletePath !== '/' ? `/menu/${restaurantId}` : null
      };
      let response = await validate2FA(requestBody);
      if (response.data.status == 'OK') {
        onContinue(response.data);
      } else {
        setInputErrors({ verificationCode: 'An error occured, please try again' });
      }
      setButtonDisabled(false);
    }
    setProcessing(false);
  };

  return (
    <Layout
      title='Verify your email address'
      titleColour={inApp ? 'rose-100' : 'white'}
      subtitle="Enter the verification code we've sent to your email - it should be with you soon. If you can't see the email, try checking your Junk folder."
      backgroundColour='#1C1A1A'
      heroPaddingSize='is-xxsmall'
      isSmallerTitle
      sectionSize='is-medium'
    >
      <div className="" style={{ margin: 'auto', paddingLeft: '1.5rem', paddingRight: '1.5rem', paddingBottom: '4rem', marginTop: '0.75rem' }}>
        <div className='form-width-desktop' style={{display: 'flex', flexDirection: 'column'}}>
          <InputField
            title="Verification Code"
            name='verificationCode'
            value={inputValues.verificationCode}
            valueCallback={handleChange}
            error={inputErrors.verificationCode}
            errorCallback={handleError}
            required={true}
            maxWidth
            placeHolder='Enter your verification code'
          />
          {processing ? (
            <div className="btn btn-primary" style={{ margin: '16px auto', padding: '12px 32px'}}>
              <img src={LoaderWhite} style={{ width: 24, height: 24 }} />
            </div>
          ) : (
            <button
              onClick={handleValidate}
              className="btn btn-primary"
              id='continueButton'
              style={{ margin: '16px auto'}}
              disabled={buttonDisabled}
            >
              Continue
            </button>
          )}
          {
            confirmRequested ?
              <p className='dm-sans' style={{ fontSize: '18px', textAlign: 'center' }}>Code Sent</p>
              :
              <p id='sendAnotherCodeButton' className='dm-sans' onClick={sendEmail} style={{ textDecoration: 'underline', fontSize: '18px', cursor: 'pointer', textAlign: 'center' }}>
                Send another code
              </p>
          }
        </div>
      </div>
    </Layout >
  );
};

export default VerifyEmail;
