import { createSlice } from '@reduxjs/toolkit';
import { applicationApi } from '../services/application.api';
import { customerApi } from '../services/customer.api';

const initialState = {
  menu: [],
  filters: [],
  activeCategory: '',
  notification: '',
  restaurant: {},
  bundles: [],
  baseSelections: [],
  outOfStockItems: [],
  outOfStockItemsVersion: 0
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    updateFilters(state, { payload }) {
      state.filters = payload;
    },
    setActiveCategory(state, { payload }) {
      state.activeCategory = payload;
    },
    setRestaurant(state, { payload }) {
      state.restaurant = payload;
    },
    setBaseSelections(state, { payload }) {
      state.baseSelections = payload;
    },
    clearBundles(state) {
      state.bundles = [];
    },
    clearActiveCategory(state) {
      state.activeCategory = '';
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      applicationApi.endpoints.getApplicationState.matchFulfilled,
      (state, { payload }) => {
        if (payload.menu) {
          state.menu = payload.menu.categories;
          if (payload.outOfStockItems) {
            state.outOfStockItems = payload.outOfStockItems.outOfStockItems;
            state.outOfStockItemsVersion = payload.outOfStockItems.version;
          }
          if (!payload.menu.categories.includes(state.activeCategory)) {
            state.activeCategory = payload.menu.categories[0];
          }
          state.bundles = payload.menu?.bundles?.bundles;
        } else {
          return initialState;
        }
      }
    );
    builder.addMatcher(
      customerApi.endpoints.logout.matchFulfilled,
      () => {
        return initialState;
      }
    );
  }
});

export const { getMenu, clearBundles, clearActiveCategory, updateFilters, refreshMenu, setActiveCategory, setBaseSelections, setNotification } = menuSlice.actions;
export default menuSlice.reducer;