import React from 'react';
import Cross from '../shared/Cross';

const formatText = (text, fontSize) => {
  if (!text) return '';
  const lines = text.split('/n');
  return lines.map((line, idx) => {
    const strongRegex = new RegExp(/[*](.+)[*]/gm);

    const isBold = strongRegex.test(line);
    if (isBold) return (
      <p
        key={`notifcation-modal-description-${idx}`}
        style={{
          fontWeight: 700,
          fontSize,
          lineHeight: '140%',
          marginBottom: 10,
          maxWidth: 400
        }}
        data-testid='notification-modal-description'
      >
        {line.replace(/\*/g, '')}
      </p>
    );
    return (
      <p
        key={`notifcation-modal-description-${idx}`}
        style={{
          fontSize,
          lineHeight: '140%',
          marginBottom: 10,
          maxWidth: 300
        }}
        data-testid='notification-modal-description'>
        {line}
      </p>);
  });
};
const NotificationModal = ({ onClose, notificationState, inApp, warning }) => {
  const { heading = null, description = null, redirectCta, redirectFn, cancel } = notificationState;
  const handleRedirect = () => {
    onClose();
    redirectFn();
  };
  
  return (
    <div style={{
      width: '100%',
      textAlign: 'center',
      position: 'relative',
      padding: !inApp ? '3rem 1rem 0' : null,
    }}>
      {inApp
        ? <img src={warning.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
        : <Cross onClick={onClose} topPosition={16} rightPosition={16} absolute />
      }
      {heading && <h1 className="title is-size-2 is-spaced pt-5 is-uppercase" style={{ marginBottom: '1rem', lineHeight: '3rem' }} data-testid='notification-modal-heading'>{heading}</h1>}
      {/* removing the following will break the modal for changing the order for collection when order is not available as it does not have a cancel button */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        {description && formatText(description, cancel ? 16 : 14)}
      </div>

      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 8, marginTop: '24px', paddingBottom: inApp && 8, marginBottom: !inApp && '1.5rem' }}>
        {
          redirectCta &&
          <button id='confirm' data-testid='notification-modal-cta' style={{ width: '190px' }} className='btn btn-primary' onClick={handleRedirect}>
            {redirectCta}
          </button>
        }
        {
          (!!cancel || (inApp && !redirectCta)) &&
          <button
            className={`btn ${redirectCta ? 'btn-secondary' : 'btn-primary'}`}
            onClick={onClose}
            style={{ width: '190px' }}
          >
            {cancel ? cancel : 'Cancel'}
          </button>
        }
      </div>
    </div>
  );
};

export default NotificationModal;