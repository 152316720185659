import React, { useState } from 'react';
import Cross from '../shared/Cross';

const TermsAndConditionsModal = ({ onClose, tcs, inApp }) => {
  const [slideOut, setSlideOut] = useState(false);

  const formatTermsConditions = tcs?.split(/\r?\n/).filter(para => para);
  const onSlideOut = () => {
    if (inApp) return;
    setSlideOut(true);
    setTimeout(onClose, 250);
  };

  return (
    <div className={`filter-modal is-text-center is-relative is-flex is-justify-content-center ${slideOut ? 'slideOut' : ''}`} style={{ backgroundColor: 'white', height: '100%' }}>
      {
        !inApp &&
        <div className='mobile-drag-bar-container' onTouchMove={onSlideOut}>
          <div className='mobile-drag-bar'></div>
        </div>
      }
      <div
        className="is-absolute hide-on-mobile"
        style={{
          right: '32px',
          top: '1rem',
          width: '2rem',
          height: '2rem'
        }}>
        <Cross onClick={onClose} />
      </div>
      <div className='terms-conditions-content'>
        <h3 className="terms-conditions-title">TERMS AND CONDITIONS</h3>
        <div className="block has-text-left terms-conditions-text-container">
          {tcs !== undefined ? formatTermsConditions?.map((p, i) => {
            return <p key={i}>{p}</p>;
          }) : null}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;