import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { historyMW } from '../helpers/routingHelpers';
import { useBreakPoint } from '../hooks/useBreakPoint';
import CheckoutBanner from './PopupBanner';

const PopupBannerController = ({
  screenWidth,
  basket,
  children,
  hideCheckout,
  popupOffset,
  isMenu,
  justifyEnd,
  parentRef,
  skipAnimation
}) => {
  const history = useHistory();
  const basketTotal = useSelector(state => state.basket.subTotal);
  const isSmallScreen = useBreakPoint(screenWidth, 555);
  const { isDelivery } = useSelector(state => state.basket);

  const navigateTo = (path) => {
    historyMW.push(path, isDelivery, history);
  };

  return <CheckoutBanner
    screenWidth={screenWidth}
    basket={basket}
    navigateTo={navigateTo}
    basketTotal={basketTotal}
    isSmallScreen={isSmallScreen}
    hideCheckout={hideCheckout}
    popupOffset={popupOffset}
    isMenu={isMenu}
    justifyEnd={justifyEnd}
    parentRef={parentRef}
    skipAnimation={skipAnimation}
  >
    {children}
  </CheckoutBanner>;
};

export default PopupBannerController;
