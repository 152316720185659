import React from 'react';
import checkSelected from '../../img/icons/checked.svg';
import checkNotSelected from '../../img/icons/unchecked.svg';

const GreenTickAndText = ({ selected, setSelected, children, id, error, compRef, disabled, noMargin }) => {
  return (
    <label
      ref={compRef}
      id={id}
      className={`t-and-c checkbox is-flex is-justify-content-flex-start ${error ? 'error' : ''}`}
      style={{margin: noMargin ? '0' : null}}
    >
      <img
        onClick={() => {
          if (!disabled) {
            setSelected(!selected);
          }
        }}
        style={{ 
          width: 31.05, // this is needed to avoid any movement when radio button is selected
          height: 32,
          marginRight: 16, 
          cursor: disabled ? 'not-allowed' : 'pointer', 
          opacity: disabled ? 0.6 : 1
        }}
        className="check"
        src={selected ? checkSelected : checkNotSelected}
        alt="checked"
      />
      <span>
        {children}
      </span>
    </label>
  );
};

export default GreenTickAndText;