import React, { useCallback, useState } from 'react';
import InputField from '../../../components/InputField';
import Section from '../../../components/Section';
import Layout from '../../../components/Layout';
import { useMobileUpdateMutation } from '../../../services/customer.api';

const VerifyMobile = ({ onContinue }) => {
  const [inputValues, setInputValues] = useState({ mobileNumber: '' });
  const [inputErrors, setInputErrors] = useState({ mobileNumber: '' });
  const [mobileUpdate, { isLoading: isUpdatingMobile }] = useMobileUpdateMutation();

  const handleChange = useCallback((name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (inputValues.mobileNumber === '' || inputErrors.mobileNumber !== '') {
      setInputErrors({ mobileNumber: 'Please enter a valid mobile number' });
    } else {
      let { data } = await mobileUpdate({ mobileNumber: inputValues.mobileNumber });
      onContinue({ ...data, mobileNumber: inputValues.mobileNumber });
    }
  };

  return (
    <Layout
      // backgroundColour='orange'
      title='Logged in successfully'
      subtitle='We need a contact number to update you if there are any changes to your order'
    >
      <Section>
        <form className="column is-12" onSubmit={handleSubmit}>
          <div className="column is-12" style={{ width: 500, maxWidth: '100%', margin: 'auto', position: 'relative', bottom: 40 }}>
            <InputField
              title="Mobile number"
              name='mobileNumber'
              value={inputValues.mobileNumber}
              valueCallback={handleChange}
              error={inputErrors.mobileNumber}
              errorCallback={handleError}
              valueType="tel"
              type="tel"
              required={true}
              maxLength="11"
              id='mobileNumberInput'
            />
          </div>
          <div className="column is-12" style={{ marginTop: -30 }}>
            <div className="block has-text-centered" style={{ margin: 'auto' }}>
              <input
                disabled={isUpdatingMobile}
                type="submit"
                className="btn btn-primary w10"
                id='continueButton'
                value='Continue' />
            </div>
          </div>
        </form>
      </Section>
    </Layout>
  );
};

export default VerifyMobile;
