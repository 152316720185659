import { useEffect, useState } from 'react';

// Default behaviour min-width breakpoint
export const useBreakPoint = (screenWidth, breakPoint = 665, min = true, other = []) => {
  const [isSmallScreen, setIsSmallScreen] = useState(screenWidth < breakPoint);
  useEffect(() => {
    const isSmall = min ? screenWidth < breakPoint : screenWidth > breakPoint;
    setIsSmallScreen(isSmall);
  }, [screenWidth, ...other]);
  return isSmallScreen;
};