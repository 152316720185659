import React from 'react';
import errorIcon from './../img/icons/error.svg';

const DropDown = (props) => {
  const { children, errorMessage, isInputValid} = props;
  return (
    <div className="block" style={{ position: 'relative', marginBottom: '0.25rem' }}>
      <div className="">{children}</div>
      {errorMessage && !isInputValid && (
        <div className="level is-mobile is-family-code">
          <div className="level-item is-narrow is-shrinkable is-align-top">
            <i className="icon is-small">
              <img src={errorIcon} alt="error" />
            </i>
          </div>
          <div className="level-item is-growable is-shrinkable is-justify-content-flex-start is-align-top">
            <div className="error-message">{errorMessage}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
