import React, { useEffect, useRef, useState } from 'react';
import useBodyScrollLock from '../../hooks/useBodyScrollLock';

export const DraggableModal = ({
    onClose,
    show,
    children,
    backgroundClass,
    bodyLock = true,
    contentCanScroll = false,
    isLoading = false,
    style = {},
    invertHandle = false
}) => {
    const [yPosition, setYPosition] = useState(600);
    const [prevYPosition, setPrevYPosition] = useState(false);
    const [shouldTransition, setShouldTransition] = useState(true);
    const [lastDelta, setLastDelta] = useState(0);
    const [containerRef, setContainerRef] = useState(false);
    // Opening transition vs returning to max transition
    const transitionTime = 300;
    // offset for padding height
    const openPosition = 0;
    const closedPosition = containerRef?.offsetHeight ?? 700;
    const [isScrolling, setIsScrolling] = useState(false);
    // Scrolling cool down period
    const scrollingTimer = useRef(false);
    // Delay to ignore drag events while scroll state updates
    const [interactionDisableTimer, setInteractionDisableTimer] = useState(false);
    // Internal open state to allow transition out on close
    const [isOpen, setIsOpen] = useState(false);
    const timerRef = useRef(false);
    const [showBackground, setShowBackground] = useState(false);

    useBodyScrollLock(bodyLock && show);

    useEffect(() => {
        clearTimeout(timerRef.current);
        if (show) {
            setIsOpen(true);
            setShowBackground(true);
        } else if (isOpen && !show && !isLoading) {
            handleTransitionOut();
        }
    }, [show, isLoading]);

    useEffect(() => {
        if (isOpen) {
            setYPosition(openPosition);
        }
        const handleScrolling = () => {
            setIsScrolling(true);
            clearTimeout(scrollingTimer.current);
            scrollingTimer.current = setTimeout(() => setIsScrolling(false), 300);
        }
        // No need to use this for modals we aren't expecting to scroll 
        if (contentCanScroll) {
            window.addEventListener('scroll', handleScrolling, true);
        }
        return window.removeEventListener('scroll', handleScrolling);
    }, [isOpen, contentCanScroll])

    // Get scroll position start and set transition type
    const handleDragStart = (e) => {
        e.stopPropagation();
        if (isScrolling) return;
        setShouldTransition(false);
        setPrevYPosition(e.targetTouches[0].pageY);
        setLastDelta(0);
        if (contentCanScroll) setInteractionDisableTimer(setTimeout(() => setInteractionDisableTimer(false), 75));
    }

    // Update modal Y position / prevent scrolling up if at max Y position
    const handleDrag = (e) => {
        e.stopPropagation();
        if (isScrolling || interactionDisableTimer) return;
        const delta = e.targetTouches[0].pageY - prevYPosition;
        setLastDelta(delta);
        if (yPosition + delta <= openPosition) {
            setYPosition(openPosition);
            setPrevYPosition(prev => prev += delta);
            return;
        }
        setYPosition(prev => prev += delta);
        setPrevYPosition(prev => prev += delta);
    }

    // If last delta is great enough (i.e. fast swipe down) close modal / if swipe finished above threshold return to open position
    const handleDragEnd = (e) => {
        e.stopPropagation();
        if (isScrolling) return;
        setShouldTransition(true);
        if (yPosition > Math.max(200, containerRef?.offsetHeight * 0.3) || lastDelta > 10) {
            handleTransitionOut();
        }
        else setYPosition(openPosition);
    }

    const handleTransitionOut = (e) => {
        e?.stopPropagation();
        setYPosition(closedPosition);
        setShowBackground(false);
        timerRef.current = setTimeout(() => {
            setIsOpen(false);
            onClose();
        }, transitionTime);
    }

    if (isOpen) return (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                overscrollBehavior: 'none',
                zIndex: '10009'
            }}
            onPointerMove={e => e.stopPropagation()}
            onPointerUp={e => e.stopPropagation()}
            onScroll={e => e.preventDefault()}
        >
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    left: '0',
                    bottom: '0',
                    overscrollBehavior: 'none'
                }}
                onPointerUp={handleTransitionOut}
                onPointerMove={e => e.stopPropagation()}
                onScroll={e => e.preventDefault()}
            >
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        left: '0',
                        bottom: '0',
                        backgroundColor: '#1C1A1A',
                        willChange: 'opacity'
                    }}
                    className={showBackground ? 'show-c' : 'hide-c'}
                />
                <div
                    ref={setContainerRef}
                    onTouchStart={handleDragStart}
                    onTouchMove={handleDrag}
                    onTouchEnd={handleDragEnd}
                    onPointerUp={e => e.stopPropagation()}
                    onScroll={e => e.preventDefault()}

                    className={`draggable-modal-content ${backgroundClass ? 'loyalty-modal ' + backgroundClass : ''}`}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        height: 'auto',
                        width: '100%',
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        overflow: 'hidden',
                        transform: `translate(0,${yPosition}px)`,
                        transition: shouldTransition ? `transform ${transitionTime}ms ease-in-out` : null,
                        willChange: 'transform',
                        padding: '16px 24px 40px',
                        ...style
                    }}
                >
                  <div className='mobile-drag-bar-container' > 
                    <div className={`mobile-drag-bar ${invertHandle ? 'invert' : ''}`}/>
                  </div>
                    {children}
                </div>
            </div>
        </div>
    )
    else return null;
}