import React, { useRef, useState } from 'react';
import errorIcon from './../img/icons/error.svg';
import closeError from './../img/icons/clear.svg';
import see from './../img/icons/see.svg';
import seeCross from './../img/icons/seeCross.svg';

const PasswordField = ({ title, name, value, valueCallback, error, errorCallback, required, disableChecks, maxWidth, placeHolder = 'Enter your password' }) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const inputRef = useRef();
  const checkPassword = () => {
    if (disableChecks) return true;

    let strength = 0;
    if (value.length > 7) {
      strength += 1;
    }
    if (value.toLocaleUpperCase() !== value && value.toLocaleLowerCase() !== value) {
      strength += 1;
    }
    if (/\d/.test(value)) {
      strength += 1;
    }
    return strength === 3;
  };

  const handleClear = () => {
    valueCallback(name, '');
    errorCallback(name, '');
    inputRef.current.focus();
  };

  const handleReveal = (e) => {
    e.preventDefault();
    setIsRevealed(!isRevealed);
  };


  return (
    <div key={name} className={`block is-flex is-flex-direction-column is-align-items-center input-field-container mb-0 ${maxWidth ? 'max-width' : ''}`}>
      <p className="label">{title} {required ? '*' : ''}</p>
      <div className="field has-addons" style={{ width: '100%', marginBottom: 0 }}>
        <div className={`${!value || error ? 'has-warning' : ''}`} style={{ position: 'relative', width: '100%' }}>
          <input
            id={name + 'InnerInput'}
            value={value}
            onChange={({ target: { value } }) => valueCallback(name, value)}
            onBlur={() => required && !checkPassword() ? errorCallback(name, 'Please enter a valid password') : errorCallback(name, '')}
            className={`input-field ${value ? 'is-active' : ''} ${error ? 'error' : ''}`}
            type={isRevealed ? 'text' : 'password'}
            ref={inputRef}
            placeholder={placeHolder}
          />
          <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', gap: 10, top: '50%', right: 20, transform: 'translateY(-50%)' }}>
            <img src={isRevealed ? seeCross : see} alt="passwordReveal" onMouseDown={handleReveal} style={{ cursor: 'pointer' }}  />
            {error && (
              <img style={{ cursor: 'pointer' }} onClick={handleClear} id={name + 'InnerInputError'} src={closeError} alt="closeError" />
            )}
          </div>
        </div>
      </div>
      {error && error !== ' ' ? (
        <div className="input-error-container">
          <img src={errorIcon} alt="error" style={{ width: 18 }} />
          <div className={'error-message'}>{error}</div>
        </div>
      ) : null}
    </div>
  );
};

export default PasswordField;
