import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../../../components/Section';
import Layout from '../../../components/Layout';
import tick from '../../../img/icons/checked.svg';
import { pathMW } from '../../../helpers/routingHelpers';
import { useSelector } from 'react-redux';

const PasswordResetConfirmation = () => {
  const { isDelivery } = useSelector(state => state.basket);
  return (
    <Layout backgroundColour='#1c1a1a' title='Password reset' noImage titleColour='white'>
      <Section>
        <div className="column is-12">
          <div className="block" style={{ width: 500, maxWidth: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <i style={{ width: 60, height: 60, margin: 30 }}>
              <img className="check" src={tick} alt="checked" style={{width: '100%'}} />
            </i>
            Thank you. Your password has been reset.
            <Link className='password-reset-confirmation' id='continueButton' style={{ marginTop: 30 }} to={pathMW('/checkout/login', isDelivery)}>Continue to login</Link>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default PasswordResetConfirmation;
