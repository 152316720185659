import React from 'react';
import { useSendToHome } from '../../hooks/useSendToHome';
import { useClearBasketMutation } from '../../services/basket.api';
import Cross from '../shared/Cross';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import useWindowDimensions from '../../hooks/useWindowDimensions';


const ChangePostcodeModal = ({ onClose, inApp, question }) => {
  const { width } = useWindowDimensions();
  const [clearBasket] = useClearBasketMutation();
  const isSmallScreen = useBreakPoint(width, 530);
  const sendToHome = useSendToHome();

  const onChange = async () => {
    await clearBasket();
    sendToHome();
  };

  return (
    <div
      className={`filter-modal ${inApp ? '' : "pt-4 pb-4 "}`}
      style={inApp ? { textAlign: 'center' } : {}}
    >      {inApp
      ? <img src={question.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
      : <Cross onClick={onClose} absolute rightPosition={7} topPosition={7} />
      }      <div className={'content w100'}>
        <h2 style={{
          marginTop: inApp ? 16 : 60,
          fontSize: inApp ? 32 : '',
          textTransform: inApp ? 'uppercase' : '',
          marginBottom: 0,
          lineHeight: '90%'
        }}
          className="title is-4">
          Change postcode
        </h2>
        <p style={{ margin: `1.5rem ${isSmallScreen ? '0.4rem' : '0.7rem'}`, fontSize: '1rem' }}>
          {inApp
            ? 'If you change the selected postcode, your basket will be emptied and you will need to start the order again. Do you wish to continue?'
            : 'If you change the selected postcode, your basket will need to be emptied. Do you wish to continue changing your postcode.'
          }
        </p>
        <div className="is-flex is-flex-direction-column is-align-items-center" style={{ gap: '1rem' }}>
          <button id="cancel" onClick={onClose} className="btn btn-primary" style={{ fontSize: '1rem' }}>
            Cancel
          </button>
          <button id="change" onClick={onChange} className="btn btn-secondary" style={{ fontSize: '1rem', marginBottom: inApp && 8 }}>
            Change
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePostcodeModal;
