/* eslint-disable no-unused-vars */

export const getExtras = (item) => {
  const extrasArr = item.subProducts?.reduce((acc, cur) => {
    let newArr = [];
    if (item.isGroup) newArr.push({ name: cur.name, cost: cur.cost * cur.quantity });
    return [...acc, ...newArr.concat(getExtras(cur))];
  }, []);
  if (extrasArr) return extrasArr;
  else return [];
};

export const copyMenuItemWithoutSubItems = ({ subProducts, ...item }) => item;

export const addParentReferences = (item, parent) => {
  const newItem = { ...item };
  if (parent) {
    const parentx2 = { ...parent.parent, parent: null };
    newItem.parent = { ...parent, parent: parentx2 };
  }
  newItem.subProducts = item.subProducts.map(el => addParentReferences(el, newItem));
  return newItem;
};

export const copyMenuItemWithoutParent = (item) => {
  const result = { ...item };
  delete result.parent;
  return result;
};

export function calcSubTotal(parentCost = 0) { // must remain es5 delcaration for 'this' reference to work
  if (this.subProducts) {
    const childrenTotal = this.subProducts.reduce((acc, cur) => {
      const subTotal = calcSubTotal.call(cur, cur.cost);
      const quantity = cur.quantity ? cur.quantity : 1;
      if (subTotal !== null) {
        return acc += subTotal * quantity;
      }
    }, 0);
    return parentCost + childrenTotal;

  }
}

export const convertMenuItemToBasketItem = ({ parent, min, max, subProducts, ...item }) => {
  return { ...item, quantity: 1, subProducts: [], calcSubTotal };
};

export const findQuestionOptions = (customItem, questionID) => {
  if (customItem?.subProducts?.length) {
    const question = customItem.subProducts.find(el => el.name === questionID);
    if (question && question.isGroup) {
      return question.subProducts;
    } else if (customItem.subProducts.length) {
      return customItem.subProducts.reduce((acc, cur) => [...acc, ...findQuestionOptions(cur, questionID)], []);
    }
  } else {
    return [];
  }
};

const findAndMatchOptions = (existingItems, newItems) => {
  if (!existingItems?.length || !newItems?.length) return [];
  return newItems.reduce((acc, newItem) => {
    const exists = existingItems.find(sp => sp.name.toLowerCase() === newItem.name.toLowerCase());
    if (exists) {
      const matchedOptions = findAndMatchOptions(exists.subProducts, newItem.subProducts);
      acc.push({ ...newItem, quantity: exists.quantity, subProducts: matchedOptions });
    }
    return acc;
  }, []);
}

export const addMenuItemToQuestion = (item, question, option) => {
  const radio = item.min === 1 && item.max <= item.min;
  if (radio) {
    const existingChildQuestionsWithAppliedOptions = question.subProducts?.[0]?.subProducts;
    const incomingQuestions = [...option.subProducts];
    console.log(existingChildQuestionsWithAppliedOptions)
    console.log(incomingQuestions)
    const persistableQuestions = findAndMatchOptions(existingChildQuestionsWithAppliedOptions, incomingQuestions);
    while (question.subProducts.length) { question.subProducts.pop(); }
    const formattedOption = convertMenuItemToBasketItem(option);
    if (persistableQuestions?.length) {
      formattedOption.subProducts = persistableQuestions;
      question.subProducts.push(formattedOption);
    }
    else question.subProducts.push(applyDefaultSubProducts(option)); // prevent
  } else {
    question.subProducts.push(convertMenuItemToBasketItem(option));
  }
};


export const addToppingToQuestion = (item, question, option) => {
  const currentTotal = question.subProducts.reduce((a, b) => a + b.quantity, 0);
  if (currentTotal < item.max) {
    let orderTopping = question.subProducts.find(p => p.id === option.id);
    if (!orderTopping) {
      orderTopping = convertMenuItemToBasketItem(option);
      question.subProducts.push(orderTopping);
    } else {
      orderTopping.quantity++;
    }
  }
};

export const removeToppingToQuestion = (item, question, option) => {
  let orderTopping = question.subProducts.find(p => p.id === option.id);
  if (orderTopping) {
    orderTopping.quantity--;
    if (orderTopping.quantity <= 0) {
      question.subProducts = question.subProducts.filter(p => p.id !== option.id);
    }
  }
};

export const resetQuestion = (item, parentQuestion, option) => {
  const question = parentQuestion.subProducts.find(el => option.id === el.id);
  if (question) {
    question.subProducts = [];
  } else {
    parentQuestion.subProducts.forEach(el => resetQuestion(null, el, option));
  }
};

export const dereffItems = (item) => {
  return { ...item, subProducts: item.subProducts.map(dereffItems), calcSubTotal };
};
export const structureQuickAddItem = (item) => {
  const itemWithParents = addParentReferences(item);
  const defaultItem = applyDefaultSubProducts(itemWithParents);
  return dereffItems(defaultItem);
};

export const applyDefaultSubProducts = (item) => {
  if (!item || item.subProducts?.length === 0) return { ...item, meta: {}, quantity: 1 };
  let newItem = { ...item, meta: {} };
  if (item.defaultSubProduct) {
    newItem = { ...item, subProducts: [] };
    const defaultItem = item.subProducts.find(el => el.id === item.defaultSubProduct);
    let defaultItemWithDefaults;
    if (defaultItem) {
      defaultItemWithDefaults = applyDefaultSubProducts(defaultItem);
    } else {
      defaultItemWithDefaults = applyDefaultSubProducts(item.subProducts[0]);
    }
    newItem.subProducts.push(defaultItemWithDefaults);
  } else if (!item.defaultSubProduct && item.max > 1 || item.isGroup) {
    newItem.subProducts = [];
  } else {
    newItem.subProducts = item.subProducts?.map(el => applyDefaultSubProducts(el));
  }
  return newItem;
};

export const getCals = (item, selectedBase, getDefault = false) => {
  if (!item) return '';

  const { calories, subProducts: choices, name } = item;

  if (choices?.length) {
    const portions = choices[0]?.subProducts;
    if (selectedBase) {
      const selectedBaseSubProduct = portions.find(el => el.id === selectedBase);
      if (selectedBaseSubProduct?.calories) return selectedBaseSubProduct.calories
    }

    if (getDefault) {
      if (getDefault && choices[0]?.defaultSubProduct) {
        const foundPortion = portions.find(portion => portion.id === choices[0]?.defaultSubProduct);
        if (foundPortion) return foundPortion.calories;
      }
    }

    if (portions?.length && portions[0].calories) return portions[0].calories
  }

  if (calories) return calories;

  return '';
};

export const getUniqueItems = (items) => {
  var unique = [];
  var distinct = [];

  for (let i = 0; i < items.length; i++) {
    if (!unique[items[i].id]) {
      distinct.push(items[i]);
      unique[items[i].id] = 1;
    }
  }

  return distinct;
};

// Check if any subproduct array has more than 1 option
export const checkIfCustomisable = (item) => {
  if (!item) return false;
  let customisable = false;
  const recursiveCheck = (item, i) => {
    if (item.subProducts && item.subProducts.length > 1) {
      customisable = true;
    } else if (item.subProducts) {
      item.subProducts.map(subProduct => recursiveCheck(subProduct, i + 1));
    }
  };
  item.map(subProduct => recursiveCheck(subProduct, 1));
  return customisable;
};

// Check if any subproduct has a min of more than 0, a subProduct array of more than 1 that does't include the defaultSubProduct id
export const checkIfAddable = (subproducts) => {
  if (!subproducts) return false;
  let addable = true;
  const recursiveCheck = (item, i) => {
    if (item.subProducts) {
      if (item.min > 0 && item.subProducts.length > 1 && !item.subProducts.find(subProduct => subProduct.id === item.defaultSubProduct)) {
        addable = false;
      }
      item.subProducts.map(subProduct => recursiveCheck(subProduct, i + 1));
    }
  };
  subproducts.map(subProduct => recursiveCheck(subProduct, 1));
  return addable;
};

export const checkIfPizza = (item) => {
  return item?.[0]?.name === 'Choose your base' && item?.[0]?.subProducts?.length > 1;
};


export const getCardButtons = (props, subProducts, quickAdd, navigateTo, restaurantId, id, handleCustomiseClick) => {
  const buttons = [];

  if (checkIfCustomisable(subProducts) && !props.outOfStock) buttons.push({ text: 'Customise', disabled: false, onClick: () => { handleCustomiseClick(); navigateTo(`/menu/${restaurantId}/customise/${id}`, { menuItem: props.item }) }, testId: 'navigate-customise' });
  if (checkIfCustomisable(subProducts) && props.outOfStock) buttons.push({ text: 'Customise', disabled: true, onClick: () => { }, testId: 'navigate-customise' });
  if (checkIfAddable(subProducts) && !props.outOfStock) buttons.push({ text: 'Add', disabled: false, onClick: quickAdd, testId: 'navigate-add' });
  if (checkIfAddable(subProducts) && props.outOfStock) buttons.push({ text: 'Add', disabled: true, onClick: () => { }, testId: 'navigate-add' });

  return buttons;
};

export const getDefaultPortion = (item) => {
  if (!checkIfCustomisable(item.subProducts) || !item.subProducts[0].defaultSubProduct) return false;
  return item.subProducts[0].subProducts.find(sp => sp.id === item.subProducts[0].defaultSubProduct);
};

export const checkCanSaveBasket = (restaurantClosingTime) => {
  if (!restaurantClosingTime) return false;
  const [hours, minutes] = restaurantClosingTime.split(':');
  const closingTime = new Date();
  closingTime.setHours(+hours);
  closingTime.setMinutes(+minutes);
  const cutoffTime = new Date().setTime(closingTime.getTime() - 20 * 60 * 1000);
  const now = new Date().getTime();
  return now < cutoffTime;
}