import React, { useState } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useRouteMatch as useFindRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCategory } from '../../store/menuSlice';
import Menu from './pages/Menu';
import Bundle from './pages/Bundle';
import Customise from './pages/Customise';
import { useGetMenuCategoryQuery } from '../../services/restaurant.api';
import { historyMW } from '../../helpers/routingHelpers';
import { freshRelevance, gtmEvent } from '../../helpers/commonHelpers';
import { useEffect } from 'react';

const MenuController = ({ onAddItem, onRemoveItem, notification, setNotification, refreshBasket }) => {
  const basket = useSelector(state => state.basket);
  const { restaurantId, inApp, customer } = useSelector(state => state.session);
  const { menu, filters, activeCategory, bundles } = useSelector(state => state.menu);
  const { isDelivery } = useSelector(state => state.basket);

  const { path } = useRouteMatch();
  const restaurantIdMatchDelivery = useFindRouteMatch('/delivery/menu/:restaurantId');
  const restaurantIdMatchCollection = useFindRouteMatch('/collection/menu/:restaurantId');
  const [quantity, setQuantity] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultedRestaurantId = restaurantId ?? restaurantIdMatchDelivery?.params?.restaurantId ?? restaurantIdMatchCollection?.params?.restaurantId;
  const { data: category, isLoading } = useGetMenuCategoryQuery({ id: defaultedRestaurantId, name: activeCategory });

  useEffect(() => {
    const params = { channel: inApp ? 'App' : 'Web' };
    if (customer?.customerSessionToken?.pizzaExpressId) {
      params.customerId = customer.customerSessionToken.pizzaExpressId;
    }
    gtmEvent(isDelivery ? 'delivery_view_menu' : 'collection_menu', params);
  }, [inApp]);

  const setCategory = (category) => {
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    dispatch(setActiveCategory(category));
  };

  const navigateTo = (path, state) => {
    historyMW.push(path, isDelivery, history, state);
  };

  window.getListedBundles = () => ({ categoryBundles: bundles.filter(bundle => bundle.category === activeCategory), categoryItems: category });

  return (
    <Switch>
      <Route exact path={path}>
        <Menu
          basket={basket}
          menu={menu}
          bundles={bundles}
          category={category}
          isLoading={isLoading}
          filters={filters}
          selectedCategory={activeCategory}
          setSelectedCategory={setCategory}
          addItem={onAddItem}
          removeItem={onRemoveItem}
          navigateTo={navigateTo}
          notification={notification}
          setNotification={setNotification}
          setQuantity={setQuantity}
          refreshBasket={refreshBasket}
          inApp={inApp}
        />
      </Route>
      <Route exact path={`${path}/:restaurantId/bundle/:itemId`}>
        <Bundle restaurantId={restaurantId} filters={filters} refreshBasket={refreshBasket} />
      </Route>
      <Route exact path={`${path}/:restaurantId/customise/:itemId`}>
        <Customise restaurantId={restaurantId} quantity={quantity} setQuantity={setQuantity} refreshBasket={refreshBasket} />
      </Route>
    </Switch>
  );
};

export default MenuController;
