import React from 'react';
import Close from '../../img/icons/close.svg';


const ChangeRestaurantModal = ({ onClose, changeRestaurantHandler, inApp, question }) => {

  return (
    <div className={`change-restaurant-modal-container ${inApp ? 'in-app' : ''}`}>
      <div className="change-restaurant-modal-content">
        {inApp
          ? <img src={question.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
          :
          <div className="close-icon-wrapper" >
            <i className={'icon is-large'} style={{ cursor: 'pointer' }} >
              <img src={Close} alt="close" onClick={onClose} />
            </i>
          </div>
        }
        <h1 className='title'>Are you sure?</h1>
        <p className={`info ${inApp ? 'in-app' : ''}`}>By selecting a new restaurant, you will lose the contents of your basket.</p>
        <div className={`button-wrapper ${inApp ? 'in-app' : ''}`} style={{paddingBottom: inApp && 12}}>
          <button id="stay"
            onClick={onClose}
            className="btn btn-primary restaurant-stay-btn"
          >
            Cancel
          </button>
          <button id="leave"
            onClick={() => {
              changeRestaurantHandler();
              onClose();
            }}
            className="btn btn-secondary restaurant-stay-btn"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeRestaurantModal;
