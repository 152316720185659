import React from 'react';
import { Redirect, useLocation } from 'react-router';

const MenuRedirect = () => {
  const { pathname } = useLocation();
  return (
    <Redirect to={`/collection${pathname}`} />
  );
};

export default MenuRedirect;