import { useState, useEffect } from 'react';

function getWindowDimensions(breakpoint) {
  const { clientWidth: width, clientHeight: height } = document.body;
  return {
    width,
    height,
    isSmallScreen: isSmallScreen(width, breakpoint)
  };
}

function isSmallScreen(width, breakpoint) {
  return width < (breakpoint || 769);
}

const useWindowDimensions = (breakpoint) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      const newDimensions = getWindowDimensions(breakpoint);
      if (windowDimensions?.width !== newDimensions.width || windowDimensions?.height !== newDimensions.height) setWindowDimensions(newDimensions);
    }
    const observer = new ResizeObserver(handleResize);
    observer.observe(document.documentElement || document.body)
    return () => {
      observer.disconnect();
    }
  }, [])
  return windowDimensions;
}
export default useWindowDimensions;