import React from 'react';

const Section = ({ ...props }) => {
  return (
    <>
      <div className="column is-12">
        <div className="columns">
          <div className="column is-12">
            <div className="block level is-mobile is-justfify-content-space-between mb-0">
              {props.title && (
                <div className="level-item is-shrinkable is-narrow">
                  <h2 className="title is-2 mb-0">{props.title}</h2>
                </div>
              )}
              {props.link && (
                <div className="level-item is-narrow">
                  <div className="content">
                    <a onClick={props.resetPizza} className="link">
                      {props.link}
                    </a>
                  </div>
                </div>
              )}
            </div>
            {props.copy && (
              <div className="block mt-2">
                <p>{props.copy}</p>
              </div>
            )}
          </div>
        </div>
        <div
          className={`block ${props.toEdge ? 'no-gutters' : null} ${
            props.childrenSectionBackground && props.childrenSectionBackground + ' py-5'
          }`}
        >
          <div className={`columns is-flex-wrap-wrap ${props.toEdgeMarginRemoval ? 'mx-0' : ''}`}>{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default Section;
