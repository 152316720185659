import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { historyMW } from '../../helpers/routingHelpers';

const stylesInvalidVoucherModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    borderRadius: 10,
    maxWidth: '600px'
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.2)',
    zIndex: 1000
  }
};

const InvalidVoucher = ({ isOpen }) => {
  const history = useHistory();
  const { isDelivery } = useSelector(state => state.basket);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => { }}
      style={stylesInvalidVoucherModal}
      ariaHideApp={false}
    >
      <div className="filter-modal is-text-center is-relative is-flex is-justify-content-center pt-6 pb-4">
        <div className='content w100'>
          <h3 className="title is-3">Vouchers cannot be used with bundles</h3>
          <div className="block">
            <p>
              To continue, please remove the vouchers from your basket.
            </p>
          </div>
          <div className="block pt-4">
            <button
              id="confirm"
              className="btn btn-primary w100"
              onClick={() => historyMW.push('/basket', isDelivery, history)}
            >
              Check Basket
            </button>
          </div>
        </div>
      </div>
    </Modal >
  );
};

export default InvalidVoucher;