import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { config } from './config.js';
import TagManager from 'react-gtm-module';
import AppController from './AppController';
import { useLocation } from 'react-router';
import { freshRelevance } from '../src/helpers/commonHelpers';
import './styles/custom.css';
import './styles/payment.css';
import './styles/bulma_production.min.css';
// Use the following if you want to enable source maps for debugging
// import './styles/bulma_development.min.css';
// import './styles/bulma_development.css';

const tagManagerArgs = {
  gtmId: config.GTM_ID
};

let oldPathname = '';

TagManager.initialize(tagManagerArgs);

const brand = config.BRAND;

const App = () => {
  const { inApp, appInsets } = useSelector(state => state.session);
  const { pathname } = useLocation();
  const [prevPage, setPrevPage] = useState(false);
  const pageRef = useRef();
  useEffect(() => {
    window.scrollTo({ left: 0 }, { top: 0 });

    brand === 'PE' ? document.title = 'Order Online | PizzaExpress' : document.title = 'Order Online | Milano';

    TagManager.dataLayer({
      dataLayer: {
        event: 'Changed pathname',
        from: oldPathname,
        to: pathname,
        channel: inApp ? 'App' : 'Web'
      }
    });
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    oldPathname = pathname;
  }, [pathname]);

  useEffect(() => {
    if(pathname !== prevPage) pageRef.current.scrollTop = 0;
    pageRef.current.scrollIntoView();
    setPrevPage(pathname);
  }, [pathname]);

  return (
    <div id="app-wrapper" style={appInsets ? {paddingTop: `${80 - appInsets.top / 4}px`} : null} className={`app-wrapper ${inApp ? 'in-app' : ''}`} ref={pageRef}>
      <AppController />
      <div style={{ display: 'none' }}>Version: {config.VERSION}</div>
    </div>
  );
};

export default App;
