
export const getRewardImage = (image) => {
  let imageStr;
  function makeLowerCase(value) {
    let str;
    str = value.toString().toLowerCase().replace(/\s/g, '');
    return str;
  }
  imageStr = makeLowerCase(image);
  switch (imageStr) {
    case 'side':
    case 'salad':
    case 'side/salad':
      return { image: 'side' };
    case 'dessert':
      return { image: 'dolcetti' };
    case 'doughballs':
      return { image: imageStr };
    case 'cocktail':
      return { image: imageStr };
    case 'pizza':
      return { image: 'pizza 1' };
    case 'piccolo':
    case 'kids':
      return { image: 'kids 1' };
    case 'water':
      return { image: imageStr };
    case 'softdrink':
    case 'softdrink/water':
      return { image: 'soft drink' };
    case 'cookbook':
      return { image: imageStr };
    case 'beer':
      return { image: imageStr };
    case 'glassofwine':
      return { image: 'wine glass' };
    case 'winebottle':
      return { image: 'wine' };
    case 'jazz':
      return { image: imageStr };
    case 'booster1':
      return { image: 'family bundle' };
    case 'celebrationblue':
    case 'goodwill/celebrationpink':
      return { image: 'special 2' };
    case 'booster3':
      return { image: 'pizza 4' };
    case 'celebrationred':
    case 'goodwill/celebrationorange':
      return { image: 'special 3' };
    case 'booster2':
      return { image: 'special 1' };
    case 'celebrationwhite':
    case 'goodwill/celebrationwhite':
      return { image: 'delivery & collection' };
    case 'genericimage1':
    case 'pleaseselect':
    case 'swap':
      return { image: 'generic' };
    case 'genericimage2':
      return { image: 'pizza 3' };
    case 'pizzaveg':
      return { image: 'pizza 2' };
    case 'duopizza1':
      return { image: 'pizza slices 1' };
    case 'duopizza2':
      return { image: 'pizza slices 2' };
    case 'valentines':
      return { image: 'hearts' };
    case 'genericbirthday':
      return { image: 'present' };
    case 'hotdrink':
      return { image: 'coffee' };
    default:
      return { image: imageStr };
  }
};

export const getTierDisplayName = (tierName => {
  if (!tierName) return '';

  if (tierName.toLowerCase() == 'signup')
    return 'Club Member';
  else
    return tierName;
});


export const restaurantInclusionExclusion = (restaurantRules, restaurantId) => {
  if (!restaurantRules?.inclusions && !restaurantRules?.exclusions) return true;
  if (restaurantRules?.exclusions?.includes(Number(restaurantId))) return false;
  if (restaurantRules?.inclusions?.includes(Number(restaurantId))) return true;
  if(restaurantRules?.inclusions?.length > 0 && !restaurantRules?.inclusions?.includes(Number(restaurantId))) return false;
  return true;
};
