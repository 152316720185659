import React from 'react';

const deliveryBadgeType = {
  fee: {text: 'Lowest Delivery Fee'},
  speed: {text: 'Fastest'}
};

const DeliveryBadge = ({type}) => {
  const calculatedClassList = `delivery-badge ${type}`;

  return (
    <div
      className={calculatedClassList}
    >
      <small>{deliveryBadgeType[type].text}</small>
    </div>
  );
};

export default DeliveryBadge;
