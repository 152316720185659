import React from 'react';
import { Container } from 'react-bulma-components';

const SimpleHero = ({
  heroWidthHalf,
  backButton,
  title,
  title2=undefined,
  titleColour=undefined,
  backgroundColour=undefined,
  isSmallerTitle,
  customStyle={},
  inApp = false,
  customClass
}) => {
  return (
    <section className={`simple-hero ${backgroundColour ? `has-background-${backgroundColour}` : ''}  ${inApp ? 'in-app' : ''} ${customClass ? customClass : ''}`} style={customStyle}>
      <Container className="simple-hero-content is-fluid">
        <div className={`title-simple-hero ${heroWidthHalf ? 'is-6' : backButton ? 'is-10' : 'is-12'} p-0`}>
          <div className="block content is-flex-direction-column is-justify-content-center is-align-items-center">
            <h1
              data-testid="simple-hero-title"
              className={`
                  title-text is-spaced is-uppercase
                  ${titleColour ? 'has-text-' + titleColour : ''}
                  ${isSmallerTitle ? 'is-smaller-title' : ''}
                  ${inApp ? 'in-app mb-0' : ''}
                `}
            >
              {title}
              {title2 ? (
                <span>
                  {title2}
                </span>
              ) : ''}
            </h1>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SimpleHero;
