import * as React from 'react';

const LevelItemLink = (props) => {
  const { aligned, verticalAligned, children, link, customClasses } = props;

  const Child = (props) => {
    const { child } = props;
    return (
      <div className={'level-item is-narrow is-shrinkable'}>
        <div className="content is-flex">{child}</div>
      </div>
    );
  };

  return (
    <div
      onClick={link}
      className={`level is-mobile mx-0 ml-2 ${
        aligned
          ? 'is-justify-content-' + aligned
          : 'is-justify-content-flex-start'
      } ${verticalAligned ? 'is-align-top' : ''} ${customClasses ? customClasses : ''}`}
    >
      {!!children &&
        React.Children.map(children, (child) => {
          return React.cloneElement(<Child child={child} />);
        })}
    </div>
  );
};
export default LevelItemLink;
