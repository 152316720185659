import React, { useCallback, useEffect, useState } from 'react';
import minimize from '../../../img/icons/minus-outlined.svg';
import plus from '../../../img/icons/plus-outlined.svg';
import checkboxTicked from '../../../img/icons/checked.svg';
import checkboxEmpty from '../../../img/icons/checkboxempty.svg';
import hot from '../../../img/icons/dietary/Spicy.svg';
import extraHot from '../../../img/icons/dietary/Extra-Spicy.svg';
import { getCals } from '../../../helpers/basketHelpers';
import DietaryIcons from '../../../components/DietaryIcons';
import { config } from '../../../config';

const CustomiseCard = (props) => {
  const {
    name,
    cardSelect,
    handleIncrease,
    handleDecrease,
    selected,
    cost,
    parent,
    option,
    isPeppers,
    bundleProduct,
    reachedMax,
    disabled
  } = props;
  const isRadio = parent && (parent.min === 1 && parent.max <= parent.min) || (parent.minItems === 1 && parent.maxItems <= parent.minItems);

  const [calories, setCalories] = useState(option.product?.calories || option.calories);

  const handleCardSelect = useCallback(() => {
    if (isRadio && !disabled) {
      cardSelect(option);
    }
  }, [option, parent, cardSelect, selected]);

  useEffect(() => {
      setCalories(getCals(bundleProduct ? option.product : option, true));
  }, [calories]);
  
  return (
    <>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between py-4 content customise-card" onClick={handleCardSelect} style={{ cursor: !isRadio ? '' : 'pointer', marginBottom: 0 }}>
        {/* card selected */}
        <div className='is-flex is-align-items-center' style={{ flex: '1 1 0', gap: '1rem' }}>
          {
            (isRadio) && !disabled &&
            <>
              {
                (selected) ?
                  <img src={checkboxTicked} className="icon is-medium" />
                  :
                  <img src={checkboxEmpty} className="icon is-medium" role='icon' />
              }

            </>
          }
          <div className='is-flex' style={{ justifyContent: 'center' }}>
            <div style={{ padding: 0, gap: 12, display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: '1rem' }}>
              <p style={{ opacity: disabled ? 0.5 : 1, fontSize: 16, flexShrink: 1, margin: 0 }}>{name}</p>
              <div className='customise-card-info'>
                {
                  (option.calories || calories) ?
                    <small role='calories' style={{ opacity: 0.8, display: 'flex', alignItems: 'text-top', fontSize: 14, lineHeight: '14px', height: '14px' }}>(+{option.calories ? option.calories : calories})</small>
                    :
                    null
                }
                {
                  (option.product?.dietPreferences || option.dietPreferences) &&
                  <DietaryIcons icons={option.product?.dietPreferences || option.dietPreferences} style={{ margin: 0 }} iconSize={20} />
                }
                {
                  cost && !isPeppers ?
                    <small style={{ display: 'flex', alignItems: 'center', fontSize: 14, lineHeight: '100%', }}>{config.CURRENCY_SYMBOL}{cost?.toFixed(2)}</small>
                    :
                    null
                }
                {
                  option.upliftPrice &&
                  <small style={{ lineHeight: '100%' }}>{config.CURRENCY_SYMBOL}{option.upliftPrice.toFixed(2)}</small>
                }
              </div>
            </div>

          </div>
        </div>

        <div className="is-flex is-shrinkable is-justify-content-flex-end">
          <div className="is-flex is-align-items-center" style={{ gap: 8 }}>
            {/* Pizza topping select card */}
            {!isRadio && !disabled &&
              <>
                {selected && selected.quantity > 0 &&
                  <>
                    <img src={minimize} className="icon is-medium" role='icon' onClick={handleDecrease} style={{ cursor: 'pointer' }} />

                    <p className="domain-display" style={{ fontSize: 20, width: 35, margin: 0, textAlign: 'center' }}>{selected.quantity}</p>
                  </>
                }
                <img
                  src={plus}
                  className="icon is-medium"
                  role='icon'
                  style={{
                    opacity: !(selected && cost === 0 && selected.quantity >= 3) && !reachedMax ? 1 : 0.2,
                    cursor: !(selected && cost === 0 && selected.quantity >= 3) && !reachedMax ? 'pointer' : null
                  }}
                  onClick={reachedMax || (selected && cost === 0 && selected.quantity >= 3) ? null : handleIncrease}
                />
              </>
            }

            {
              getPeppers(option.uiTags)
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomiseCard;

export const getPeppers = (uiTags) => {
  if (!uiTags) return null;
  else if (uiTags?.includes('Spicy 1')) {
    return <div className="level-item">
      <img src={hot} className="icon" role='icon' />
    </div>;
  } else if (uiTags?.includes('Spicy 2')) {
    return <div className="level-item">
      <img src={extraHot} className="icon" role='icon' />
    </div>;
  } else {
    return null;
  }
};