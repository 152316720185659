import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import InputField from '../../../components/InputField';
import checked from '../../../img/icons/checked.svg';
import Layout from '../../../components/Layout';
import { useForgottenPasswordMutation } from '../../../services/customer.api';
import { useSelector } from 'react-redux';
import { freshRelevance } from '../../../helpers/commonHelpers';

const ForgottenPassword = () => {
  const [inputValues, setInputValues] = useState({ emailAddress: '' });
  const [inputErrors, setInputErrors] = useState({ emailAddress: '' });
  const [isResetSent, setIsResetSent] = useState(false);
  const [forgottenPassword, { isLoading }] = useForgottenPasswordMutation();
  const history = useHistory();
  const { inApp } = useSelector(state => state.session);
  const restaurantId = useSelector(state => state.session.restaurant.id);
  const checkoutCompletePath = useSelector(state => state.session.checkoutCompletePath);

  const handleChange = useCallback((name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const handleIsResetSent = async (e) => {
    e.preventDefault();

    if (inputValues.emailAddress === '' || inputErrors.emailAddress !== '') {
      setInputErrors({ emailAddress: 'Please enter a valid email address' });
    } else {
      const response = await forgottenPassword({
        email: inputValues.emailAddress,
        restaurantId,
        redirectUrl: checkoutCompletePath && checkoutCompletePath !== '/' ? `/menu/${restaurantId}` : null
      });

      if (response?.data?.success) {
        setIsResetSent(!isResetSent);
        freshRelevance('pageChange', null, { 'sendBeacon': true });
      } else if (!response?.data?.exists) {
        setInputErrors({ emailAddress: 'We don’t recognise this email address.' });
      }
    }
  };

  return (
    <Layout
      backgroundColour='#1C1A1A'
      title={!isResetSent ? 'Forgotten password?' : 'Password reminder'}
      titleColour={inApp ? 'rose-100' : 'white'}
      subtitle={!isResetSent ? 'Please give us your email address so that we can send you an email letting you know how to restore your password.' : null}
      backButton={history.goBack}
      noImage
      heroPaddingSize='is-xxsmall'
      isSmallerTitle
      sectionSize='is-medium'
    >
      {!isResetSent ? (
        <div className="" style={{ margin: 'auto', paddingLeft: '1.5rem', paddingRight: '1.5rem', paddingBottom: '4rem', marginTop: '0.75rem' }}>
          <form onSubmit={handleIsResetSent} className="form-width-desktop">
            <InputField
              title="Enter your email address"
              optionalTitle='email address'
              name='emailAddress'
              value={inputValues.emailAddress}
              valueCallback={handleChange}
              error={inputErrors.emailAddress}
              errorCallback={handleError}
              valueType="email"
              type="email"
              required={true}
              id='emailAddressButton'
            />
            <div className="is-flex is-justify-content-center">
              <input
                type="submit"
                className="btn btn-primary w10 mt-4"
                id='resetPasswordButton'
                disabled={isLoading || inputErrors.emailAddress?.length}
                value="Reset your password"
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="column is-12">
          <div className="block" style={{ width: 500, maxWidth: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: -60 }}>
            <i style={{ width: 60, height: 60, margin: 30 }}>
              <img className="check" src={checked} alt="checked" style={{ width: '100%' }} />
            </i>
            Thank you, we have sent you an email to reset your password.
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ForgottenPassword;
