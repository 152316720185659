import React from 'react';
import Cross from '../shared/Cross';
import { useBreakPoint } from '../../hooks/useBreakPoint';

const CollectionTimeErrorModal = ({ onClose, screenWidth, inApp, warning }) => {
  const isSmallScreen = useBreakPoint(screenWidth, 530);

  return (
    <div
      className={`filter-modal  ${inApp ? '' : " pb-5 pt-4"}`}
      style={inApp ? { textAlign: 'center' } : {}}
    >
      {inApp ?
        <img src={warning.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
        : <Cross onClick={onClose} absolute rightPosition={7} topPosition={7} />
      }
      <div className='content w100'>
        <h3 style={{
          marginTop: inApp ? 16 : 60,
          fontSize: inApp ? 32 : '',
          textTransform: inApp ? 'uppercase' : '',
          marginBottom: 0,
          lineHeight: '90%'
        }} className="title is-4">Error</h3>
        <div className="block">
          <p style={{ margin: inApp ? '1rem 0rem' : '1.5rem 0.7rem' }}>
            Your collection time has expired, please select a new collection time before completing your order.
          </p>
        </div>
        <div className="block">
          <button
            id="confirm"
            className="btn btn-primary w100"
            onClick={onClose}
            style={inApp ? { maxWidth: '12rem', marginBottom: 8, fontSize: '16px' } : { fontSize: '16px' }}
          >
            Select new time
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollectionTimeErrorModal;