import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBaseName } from '../../../helpers/bundleHelpers';
import chevronDown from '../../../img/icons/chevronDown.svg';
import CustomiseCard from './CustomiseCard';

const BundleCustomiseSection = ({
  bundleSection,
  customisedBundle,
  handleSelect,
  handleIncrease,
  handleDecrease,
  showErrors,
  invalidSections,
  setInvalidSections,
  compare,
  disabledItems,
  outOfStockItems
}) => {
  const [open, setOpen] = useState(true);
  const { name } = bundleSection;
  const [error, setError] = useState();
  const { filters } = useSelector(state => state.menu);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [itemNumber, setItemNumber] = useState(0);

  const getItemNumber = () => {
    const section = customisedBundle?.sections.find(el => el.id === bundleSection.id);
    return section?.products.reduce((acc, cur) => acc + cur.product.quantity, 0);
  };

  useEffect(() => {
    setItemNumber(getItemNumber());
  }, [customisedBundle]);


  const checkSelected = (product) => {
    const exists = customisedBundle.sections.filter(section => section.id === bundleSection.id).reduce((acc, cur) => {
      const productName = `${getBaseName(product)}${product.product.name}`;
      const item = cur.products.find(p => productName === `${getBaseName(p)}${p.product.name}`);
      if (item) {
        acc = item.product;
      }
      return acc;
    }, false);
    return exists;
  };

  useEffect(() => {
    const invalid = invalidSections.find(el => el === bundleSection.id);
    if (invalid) {
      setError(true);
    } else {
      setError(false);
    }
  }, [invalidSections]);

  useEffect(() => {
    const customBundleSection = customisedBundle?.sections.find(el => el.id === bundleSection.id);
    if (customBundleSection?.products.reduce((partial, p) => partial + p.product.quantity, 0) < bundleSection.minItems) {
      setInvalidSections(sections => [...sections, bundleSection.id]);
    } else {
      setInvalidSections(sections => sections.filter(el => el !== bundleSection.id));
    }

  }, [customisedBundle]);

  const handleCardSelect = (product) => {
    handleSelect(product, customisedBundle, bundleSection.id, bundleSection.maxItems);
  };

  const handleCardIncrease = (product) => {
    handleIncrease(product, customisedBundle, bundleSection.id, bundleSection.maxItems);
  };

  const handleCardDecrease = (product) => {
    handleDecrease(product, customisedBundle, bundleSection.id);
  };

  useEffect(() => {
    let newProducts = [...bundleSection.products];
    setSortedProducts(newProducts.sort(compare));
  }, [bundleSection?.products]);

  return (
    <>
      <div className='block customise-section' data-section-error-id={error ? bundleSection.id : null}>
        <div className='is-flex is-justify-content-space-between is-align-items-start is-pointer' style={{ marginBottom: open ? 24 : 0 }} onClick={() => setOpen(o => !o)}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <h2 className='title customise-section-header' style={{ lineHeight: '1.2' }}>{name}</h2>
            {
              error && showErrors ?
                <p className='error' style={{ color: 'crimson' }}>{'Please select an option'}</p>
                :
                null
            }
          </div>
          <img src={chevronDown} style={{ width: 24, height: 24, transform: `rotate(${!open ? '180deg' : 0})` }} />
        </div>
        {
          open &&
          <div className='mt-2'>
            {
              sortedProducts
                .filter(el => filters.every(pref => el.product.dietPreferences.includes(pref)))
                .filter(el => {
                  const outOfStockItem = outOfStockItems.includes(el.product.id);
                  return !outOfStockItem;
                })
                .map((el, idx) => {
                  return (
                    <CustomiseCard
                      name={getBaseName(el) ? `${el.product.name} ${getBaseName(el)}` : el.product.name}
                      bundleProduct
                      option={el}
                      selected={checkSelected(el)}
                      cardSelect={() => handleCardSelect(el)}
                      handleIncrease={() => handleCardIncrease(el)}
                      handleDecrease={() => handleCardDecrease(el)}
                      reachedMax={itemNumber >= bundleSection.maxItems}
                      key={idx}
                      disabled={disabledItems.includes(el.product.id)}
                      parent={bundleSection}
                    />
                  )
                }
                )
            }

          </div>
        }
      </div>
    </>
  );
};

export default BundleCustomiseSection;