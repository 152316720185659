import React, {Fragment} from 'react';
import MenuItem from '../../../components/MenuItem';
import { bundleDisplayIdxComparer, filterAvailableBundles, isBundleInStock } from '../../../helpers/bundleHelpers';


const ItemMenu = ({
  isLoading,
  category,
  filters,
  basket,
  width,
  isSmallScreen,
  navigateTo,
  addItem,
  removeItem,
  findItemQuantity,
  bundles,
  outOfStockItems,
}) => {
  return (
    <>
      {!isLoading && category?.subCategories?.length && [...category?.subCategories]
        .sort((a,b) => a.displayIndex - b.displayIndex)
        .map((sc, index) => {
          const bundlesToRender = filterAvailableBundles(bundles, sc?.name)
            .filter(el => filters.every(pref => el.dietPreferences.includes(pref)));
          const itemsToRender = sc.subProducts
            .filter(item => {
              if (!filters.length) return true;
              if (filters.every(pref => item.dietPreferences.includes(pref))) return true;
            });
          if (bundlesToRender?.length === 0 && sc.subProducts?.length === 0) {
            return (<Fragment key={index}></Fragment>);
          }
          return (
            <Fragment key={`subcategory${index}`}>
              <div style={{ width: '100%', padding: '1rem 1rem' }}>
                <h1 className={`title ${index > 0 ? 'category' : ''}`} style={{lineHeight: '90%'}}>{sc.name}</h1>
              </div>
              {
                !isLoading && category && 
                  bundlesToRender
                    .sort(bundleDisplayIdxComparer)
                    .map((el, idx) => {
                      const inStock = isBundleInStock(el, outOfStockItems);
                      let itemBasketIndex = -1;
                      if (basket && basket.bundles) {
                        itemBasketIndex = basket.bundles.findIndex(bItem => bItem.name === el.name);
                      }
                      return (
                        <MenuItem
                          item={el}
                          basket={basket}
                          screenWidth={width}
                          isSmallScreen={isSmallScreen}
                          navigateTo={navigateTo}
                          bundleItem
                          menuItem
                          columnWidth={4}
                          key={el.name}
                          outOfStock={!inStock}
                          hideCalories
                          numberInBasket={itemBasketIndex === -1 ? 0 : findItemQuantity(el.name, true)}
                          idx={idx}
                        />
                      );
                    })
              }
              {
                itemsToRender
                  .map((item) => {
                    const outOfStock = outOfStockItems.includes(item.id);
                    return { ...item, outOfStock };
                  })
                  .sort((a, b) => (a.outOfStock === b.outOfStock) ? 0 : (a.outOfStock ? 1 : -1))
                  .map((item, i) => {
                    let itemBasketIndex = -1;
                    if (basket && basket.items) {
                      itemBasketIndex = basket.items.findIndex(bItem => bItem.id === item.id);
                    }
                    return (
                      <Fragment key={`pizza-${item.id}`}>
                        <MenuItem
                          id={'pizza-' + i}
                          index={i}
                          item={{ ...item, cost: item.displayPrice }}
                          outOfStock={item.outOfStock}
                          columnWidth={4}
                          menuItem={true}
                          numberInBasket={itemBasketIndex === -1 ? 0 : findItemQuantity(item.id)}
                          screenWidth={width}
                          addItem={addItem}
                          removeItem={removeItem}
                          navigateTo={navigateTo}
                          dietaryOptions={item.dietPreferences}
                          isLast={i === sc.subProducts.length - 1}
                        />
                      </Fragment>
                    );
                  })
              }
            </Fragment>
          );
        }
        )}
    </>
  );
};

export default ItemMenu;
