import React from 'react';
import Cross from '../shared/Cross';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import exclamationMark from '../../img/icons/exclamationMark.svg';

const GenericModal = ({ onClose, title, message, buttonTitle, inApp, warning }) => {
  const { width } = useWindowDimensions();
  const isSmallScreen = useBreakPoint(width, 530);

  return (
    <div
      className={`filter-modal  ${inApp ? '' : "pt-4 pb-4"}`}
      style={inApp ? { textAlign: 'center' } : {}}
    >
      {inApp
        ? <img src={warning.src} style={{ width: 120, marginTop: inApp ? '1.5rem' : '' }} />
        : <Cross onClick={onClose} absolute rightPosition={7} topPosition={7} />
      }
      <div className='content w100'>
        <h2 style={{
          marginTop: inApp ? 16 : 60,
          fontSize: inApp ? 32 : '',
          marginBottom: 0,
          lineHeight: '90%'
        }}
          className="title is-4">
          {title}
        </h2>
        <p style={{ margin: `1.5rem ${isSmallScreen ? '0.4rem' : '0.7rem'}`, fontSize: '1rem' }}>
          {message}
        </p>
        <div className="is-flex is-flex-direction-column is-align-items-center" style={{ gap: '0.5rem' }}>
          <button
            id="confirm"
            className="btn btn-primary"
            onClick={onClose}
            style={{ fontSize: '1rem', maxWidth: inApp && '12rem', marginBottom: inApp && 8 }}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenericModal;