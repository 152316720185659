import React from 'react';
import { useClearBasketDeliveryMutation } from '../../services/basket.api';
import Cross from '../shared/Cross';


export const DeliveryUpdatedModal = ({ onClose, restaurantId, deliveryEstimate, inApp, warning }) => {
  const [clearBasketDelivery] = useClearBasketDeliveryMutation();
  const onChange = async () => {
    await clearBasketDelivery();
    window.location.href = `/collection/menu/${restaurantId}`;
  };
  return (
    <div className={inApp ? 'filter-modal ': 'filter-modal pt-4 pb-5' }
      style={{ textAlign: inApp ? 'center' : '' }}
    >
      {inApp
        ? <img src={warning.src} style={{ width: 120, margin: '1.5rem 0' }}/>
        : <Cross topPosition={0} rightPosition={0} absolute onClick={onClose} />
      }
      <div className='px-5-desktop'>
        <h1 className="title is-spaced" style={{ marginBottom: inApp ? '1rem' : '2rem', fontSize: 32 }}>WE’RE SORRY!</h1>
        <p className='modal-text' style={{ fontSize: inApp ? 16 : 18 }}>Unfortunately due to a busy period, the delivery time has changed to arrive in {deliveryEstimate?.begin}&nbsp;-&nbsp;{deliveryEstimate?.end}&nbsp;mins.</p>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: 10, marginTop: '1rem' }}>
          <button style={{padding: inApp && '1rem 0', maxWidth:inApp && '12rem'}} id="change" onClick={onClose} className="btn btn-primary w100-mobile">
            Continue
          </button>
          <button style={{padding: inApp && '1rem 0', maxWidth:inApp && '12rem'}} id="cancel" onClick={onChange} className="btn btn-secondary w100-mobile">
            Order for Collection
          </button>
        </div>
      </div>
    </div>
  );
};
