import React from 'react';
import Hero from './Hero';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

export const Layout = ({
  hideHero,
  backgroundColour,
  title,
  title1,
  title2,
  title3,
  subtitle,
  ctaCopy,
  ctaLink,
  ctaFunction,
  backButton,
  isFluid,
  allergens,
  children,
  collapsePadding,
  section = '',
  heroWidthHalf,
  heroPaddingSize,
  containerBreakpoint,
  titleStyles,
  sectionSize = '',
  paddingTop,
  contentPaddingSize,
  customStyles,
  copyColour,
  titleColour,
  banners,
  noImage,
  bgImage,
  heroBgImage,
  isSmallerTitle,
  extraVerticalPadding,
  isCustomise,
  isPayment,
  isSticky,
  isBasket,
  disableBackButton
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { restaurantId, inApp } = useSelector(state => state.session);

  const layoutContainer = () => {
    if (pathname.includes('/menu') || pathname.includes('/basket') || pathname.includes('/loyalty')) {
      return <>{children}</>;
    } else {
      return (<div className={`section ${section} ${collapsePadding ? 'py-4-mobile' : ''} ${sectionSize}`}>
        <div className="columns is-flex-wrap-wrap">{children}</div>
      </div>);
    }
  };

  const getBannerHref = (b) => {
    if (b.ctaLink.includes('http')) return b.ctaLink;
    return `https://${b.ctaLink}`;
  };
  
  return (
    <>
      {
        banners?.length > 0 &&
        banners?.map((b, idx) =>
          <div
            key={idx + b.colour}
            style={{
              backgroundColor: b.backgroundColour,
              color: b.colour,
            }}
            className='layout-banner-content'
            data-testid='deal-banner'
          >
            {
              b.header &&
              <h3 style={{ fontSize: 16, fontFamily: 'dm-sans-medium' }}>{b.header}</h3>
            }
            {
              b.body &&
              <p style={{fontSize: 14}}>{b.body} {b.ctaText && <a style={{ color: b.colour, fontFamily: 'dm-sans-medium' }} href={getBannerHref(b)} target="_blank" rel="noreferrer">{b.ctaText}</a>}</p>
            }
          </div>
        )
      }
      {!hideHero && (
        <Hero
          backgroundColour={backgroundColour}
          title={title}
          titleStyles={titleStyles}
          title1={title1}
          title2={title2}
          title3={title3}
          subtitle={subtitle}
          ctaCopy={ctaCopy}
          ctaLink={ctaLink}
          ctaFunction={ctaFunction}
          backButton={backButton}
          allergens={allergens}
          history={history}
          collapsePadding={collapsePadding}
          restaurantId={restaurantId}
          heroWidthHalf={heroWidthHalf}
          heroPaddingSize={heroPaddingSize}
          paddingTop={paddingTop}
          customStyles={customStyles}
          copyColour={copyColour}
          titleColour={titleColour}
          noImage={noImage}
          pathname={pathname}
          bgImage={heroBgImage}
          isSmallerTitle={isSmallerTitle}
          extraVerticalPadding={extraVerticalPadding}
          isCustomise={isCustomise}
          isPayment={isPayment}
          isSticky={isSticky}
          isBasket={isBasket}
          inApp={inApp}
          disableBackButton={disableBackButton}
        />)}
      <div
        className={`container ${isFluid === true ? 'is-fluid' : ''}  ${containerBreakpoint ? 'is-max-' + containerBreakpoint : ''} `}
        style={{
          padding: contentPaddingSize ? contentPaddingSize : null,
          backgroundImage: bgImage ? `url(${bgImage})` : '',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        }}
      >
        {layoutContainer()}
      </div>
    </>
  );
};

export default Layout;
