import React from 'react';
import LoaderBlack from '../../img/common/Loader_360x360.gif';
import LoyaltyTierHeader from './LoyaltyTierHeader';
import LoyaltySliderContent from './LoyaltySliderContent';

export const RewardPickerTester = ({ data, tier }) => {
  return (
    <div
      className={`loyalty-slider-container reward-tier-${tier}`}
      style={{ width: '100%', maxWidth: '677px', borderRadius: 16, boxSizing: 'border-box', margin: 0 }}
    >
      {((!data)) ?
        <div style={{ alignContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: '128px', paddingBottom: '128px' }}>
          <img src={LoaderBlack} style={{ width: 64, height: 64 }} />
        </div>
        :
        <>
          <LoyaltyTierHeader tierName={tier} />
          <LoyaltySliderContent data={data} colourInverted={false} tierName={tier} />
        </>
      }
    </div>
  );
};
