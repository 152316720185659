import React from 'react';
import twitter from '../img/footer/twitterlogo.svg';
import xIcon from '../img/footer/x.svg';
import linkedIn from '../img/footer/linkedinlogo.svg';
import instagram from '../img/footer/instagramlogo.svg';
import tikTok from '../img/footer/tiktoklogo.svg';
import facebook from '../img/footer/facebooklogo.svg';
import pizzaBoxes from '../img/pizza-boxes.svg';
import LiveChatBubble from '../img/footer/LiveChatBubble.svg';
import youtube from '../img/footer/youtubelogo.svg';
import googlePlay from '../img/footer/google-play-seeklogo.svg';
import appStore from '../img/footer/apple-store-seeklogo.svg';
import { Container } from 'react-bulma-components';
import { config } from '../config.js';
import { useRouteMatch } from 'react-router';
import { gtmEvent } from '../../src/helpers/commonHelpers.js';

const brand = config.BRAND;

const handleAppDownloadClick = (appType, url) => {
  const params = { appType: appType };

  gtmEvent('App_Download_Click', params);
  window.open(url, '_blank')
};

const handleSocialsClick = (socialType, url) => {
  const params = { socialType: socialType };

  gtmEvent('Socials_Click', params);
  window.open(url, '_blank')
};

const Footer = ({ footerRef, inApp, didomiObject }) => {
  const isDelivery = useRouteMatch('/delivery');
  const handleLiveChat = () => {
    window.open('https://www.pizzaexpress.com/deliveryhelp', '_blank');
  };
  const year = new Date();
  if (brand == 'PE') {
    return (
      <>
        <footer ref={footerRef} className="footer has-text-white has-background-primary" style={{ zIndex: 999 }}>
          <Container className='is-max-widescreen'>
            <section className="section is-footer m-">
              <div className="columns is-flex-wrap-wrap" style={{ position: 'relative', maxWidth: '1440px', width: '100%' }}>
                <div className="column is-12" >
                  <div className="content">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: isDelivery ? 'space-between' : 'flex-start', position: 'relative' }}>
                      <div
                        className={`social-buttons-container ${isDelivery ? 'make-space-for-help' : ''}`}
                      // style={{display: 'flex', alignItems: 'center', gap: '1.3rem'}}
                      >
                        <img src={facebook} style={{ cursor: 'pointer' }} onClick={() => handleSocialsClick('facebook', 'https://www.facebook.com/pizzaexpress/')} />
                        <img src={instagram} style={{ cursor: 'pointer' }} onClick={() => handleSocialsClick('instagram', 'https://www.instagram.com/pizzaexpress/?hl=en')} />
                        <img src={linkedIn} style={{ cursor: 'pointer' }} onClick={() => handleSocialsClick('linkedin', 'https://www.linkedin.com/company/pizzaexpress')} />
                        <img src={xIcon} style={{ cursor: 'pointer' }} onClick={() => handleSocialsClick('twitter', 'https://twitter.com/PizzaExpress?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor')} />
                        <img src={youtube} style={{ cursor: 'pointer' }} onClick={() => handleSocialsClick('youtube', 'https://www.youtube.com/user/PizzaExpressTube')} />
                        <img src={tikTok} style={{ cursor: 'pointer' }} onClick={() => handleSocialsClick('tiktok', 'https://www.tiktok.com/discover/pizzaexpress?lang=en')} />
                      </div>
                      {
                        isDelivery &&
                        <div role='deliveryHelp' style={{ position: 'absolute', right: 0, top: 10, height: 78, width: 81, transform: 'translateY(-50%)' }} onClick={handleLiveChat} >
                          <div style={{ cursor: 'pointer', position: 'relative', height: '100%', width: '100%' }}>
                            <p style={{ position: 'absolute', left: 17, top: 13, fontSize: '14px', textAlign: 'center', fontWeight: '200' }}>Delivery<br />help</p>
                            <img src={LiveChatBubble} alt='Live chat bubble' />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="column is-12">
                  <div className="my-2" style={{ height: 1, backgroundColor: '#C4C4C4' }} />
                </div>

                <div className="column is-12">
                  <div className="content">
                    <div className="block">
                      <h5 className='title is-5'>PizzaExpress Club App</h5>
                    </div>
                    <div className="block">
                      <div className="app-stores level is-mobile is-justify-content-flex-start">
                        <div className="level-item is-narrow is-shrinkable">
                            <img className="image" src={appStore} style={{ cursor: 'pointer', width: '109px' }} onClick={() => handleAppDownloadClick('apple', 'https://apps.apple.com/gb/app/pizzaexpress/id440565075')} />
                        </div>
                        <div className="level-item is-narrow is-shrinkable app-icon">
                          <img className="image" src={googlePlay} style={{ cursor: 'pointer', width: '109px' }} onClick={() => handleAppDownloadClick('google', 'https://play.google.com/store/apps/details?id=com.pizzaexpress.appv2')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-12">
                  <div className="content">
                    <div className="footer-links-wrapper level level-left level-list">
                      <div className="level-item is-narrow is-justify-content-flex-start">
                        <strong className="has-text-white">
                          <small>&copy; PizzaExpress {year.getFullYear()}</small>
                        </strong>
                      </div>
                      <div className="level-item is-narrow is-justify-content-flex-start">
                        <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.pizzaexpress.com/terms-and-conditions/online-ordering', '_blank')}>Terms & Conditions</small>
                      </div>
                      <div className="level-item is-narrow is-justify-content-flex-start">
                        <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.pizzaexpress.com/privacy-policy', '_blank')}>Privacy policy</small>
                      </div>
                      <div className="level-item is-narrow is-justify-content-flex-start">
                        <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.pizzaexpress.com/terms-of-use', '_blank')}>Terms of use</small>
                      </div>
                      <div className="level-item is-narrow is-justify-content-flex-start">
                        <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.pizzaexpress.com/cookie-policy', '_blank')}>Cookie policy</small>
                      </div>
                      <div className="level-item is-narrow is-justify-content-flex-start">
                        <small style={{ cursor: 'pointer' }} onClick={() => window.open(' https://www.pizzaexpress.com/help-and-contact', '_blank')}>Help & Contact</small>
                      </div>
                      {/* todo remove !inApp and pass didomi cookie out into user profile */}
                      {!inApp &&
                        <div className="level-item is-narrow is-justify-content-flex-start">
                          <small style={{ cursor: 'pointer' }} onClick={() => { window.Didomi.preferences.show(); }}>
                            Cookie Settings
                          </small>
                        </div>}
                    </div>
                  </div>
                </div>
                <img alt="pizza boxes" src={pizzaBoxes} style={{ position: 'absolute', right: '1rem', bottom: '25px' }} />
              </div>
            </section>
          </Container>
        </footer>
      </>
    );
  }

  return (<>
    <footer ref={footerRef} className="has-text-white has-background-primary" style={{ zIndex: 999 }}>
      <Container className={'is-max-widescreen'}>
        <section className="section is-footer">
          <div className="columns is-flex-wrap-wrap">

            <div className="column is-12">
              <div className="content">
                <div className="level level-left level-list">
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.milano.ie/about-us', '_blank')}>About Us</small>
                  </div>
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.milano.ie/help-and-contact', '_blank')}>Contact Us</small>
                  </div>
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.milano.ie/careers', '_blank')}>Careers</small>
                  </div>
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.milano.ie/privacy-policy', '_blank')}>Privacy policy</small>
                  </div>
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.milano.ie/Terms-and-Conditions/Click-and-Collect', '_blank')}>Terms And Conditions</small>
                  </div>
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.milano.ie/cookie-policy', '_blank')}>Cookie policy</small>
                  </div>
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }} onClick={() => { window.Didomi.preferences.show(); }}>
                      Cookie Settings
                    </small>
                  </div>
                  <div className="level-item is-narrow is-justify-content-flex-start">
                    <small style={{ cursor: 'pointer' }}>&copy; Milano Limited {year.getFullYear()}</small>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </Container>
    </footer>


  </>);

};

export default Footer;