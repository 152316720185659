import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import LevelItemLink from '../../../components/LevelItemLink';
import iconInfo from '../../../img/icons/iconInfo.svg';
import errorIcon from '../../../img/icons/error.svg';
import tick from '../../../img/menu/Tick.svg';

const PasswordChecker = ({ value }) => {
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    let strength = 0;
    if (value.length > 7) {
      strength += 1;
    }
    if (value.toLocaleUpperCase() !== value && value.toLocaleLowerCase() !== value) {
      strength += 1;
    }
    if (/\d/.test(value)) {
      strength += 1;
    }
    setPasswordStrength(strength);
  }, [value]);

  return (
    <>
      <div className='ml-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 40 }}>
        <div className="password-checker-strip-wrapper"
        >
          <div
            className={`password-strength ${
              (passwordStrength === 0 && value.length > 0 || passwordStrength === 1) ?
                'weak' : passwordStrength === 0 ? '' :  passwordStrength === 2 ? 'good' : 'great'}`}
            style={{ borderTopLeftRadius: 4, borderBottomLeftRadius:4 }}
          />
          <div
            className={`password-strength ${
              (passwordStrength === 0 || passwordStrength === 1) ?
                '' : passwordStrength === 2 ? 'good' : 'great'}`}
          />
          <div
            className={`password-strength ${
              (passwordStrength === 0 || passwordStrength === 1 || passwordStrength === 2) ? '' : 'great'}`}
            style={{ borderTopRightRadius: 4, borderBottomRightRadius:4 }}
          />
        </div>
        <p id='strengthIdentifier' className="strength-identifier">
          {(passwordStrength === 0 && value.length > 0 || passwordStrength === 1) ?
            'weak' :
            passwordStrength === 0 ?
              '' :
              passwordStrength === 2 ?
                'good' :
                'great'
          }
        </p>
      </div>
      <div className="password-checker-help block ml-3 mb-2">Your password must include:</div>
      <LevelItemLink customClasses="password-checker-help mb-2" aligned="flex-start">
        <i className="icon">
          <img id='eightCharIndicator' src={value.length === 0 ? iconInfo : value.length < 8 ? errorIcon : tick} alt="info" />
        </i>
        <span>At least 8 characters.</span>
      </LevelItemLink>
      <LevelItemLink customClasses="password-checker-help mb-2" aligned="flex-start">
        <i className="icon">
          <img id='upperAndLowerIndicator' src={value.length === 0 ? iconInfo : value.toLocaleUpperCase() === value || value.toLocaleLowerCase() === value ? errorIcon : tick} alt="info" />
        </i>
        <span>A mix of upper and lowercase characters.</span>
      </LevelItemLink>
      <LevelItemLink customClasses="password-checker-help" aligned="flex-start">
        <i className="icon">
          <img id='oneNumberIndicator' src={value.length === 0 ? iconInfo : /\d/.test(value) ? tick : errorIcon} alt="info" />
        </i>
        <span>At least 1 number.</span>
      </LevelItemLink>
    </>
  );
};

export default PasswordChecker;
