import React, { useEffect, useState } from 'react';
import SimpleHero from '../../../components/SimpleHero';
import { useSelector } from 'react-redux';
import Layout from '../../../components/Layout';
import CollectionTime from '../components/CollectionTime';
import { WelcomeToPEModalWrapper } from '../../../helpers/modalHelpers';
import { useHistory, useParams } from 'react-router';
import DeliveryDetails from '../components/DeliveryDetails';
import { historyMW } from '../../../helpers/routingHelpers';
import checked from '../../../img/icons/checked.svg';
import checkNotSelected from '../../../img/icons/unchecked.svg';
import { useLazyGetTimeSlotsQuery } from '../../../services/restaurant.api';
import GreenTickAndText from '../../../components/shared/GreenTickAndText';
import errorIcon from '../../../img/icons/error.svg';
import { gtmEvent } from '../../../helpers/commonHelpers';

const Details = ({ onAddItem, navigateTo }) => {
  const [isRecieveOffers, setIsRecieveOffers] = useState(false);
  const [changeWelcomeModalIsOpen, setChangeWelcomeModalIsOpen] = useState(false);
  const [collectionTimeError, setCollectionTimeError] = useState('');
  const { email, firstName, lastName, mobileNumber, discounts, postCode } = useSelector(state => state.basket);
  const appApproved = useSelector(state => state.session?.customer?.appApproved);
  const loyalty = useSelector(state => state.session?.features.loyalty);
  const { collectionTime, restaurant, customer, inApp } = useSelector(state => state.session);
  const { joined } = useParams();
  const { isDelivery } = useSelector(state => state.basket);
  const history = useHistory();
  const rewards = useSelector(state => state.loyalty?.rewards);
  const currentTier = useSelector(state => state.loyalty?.customer?.currentTier?.name);
  const [getTimeSlotsQuery] = useLazyGetTimeSlotsQuery();
  const [TCAccepted, setTCAccepted] = useState(false);
  const [TCError, setTCError] = useState(false);
  const deliveryAddress = useSelector(state => state.session.deliveryAddress);
  const canProceed = !isRecieveOffers || isRecieveOffers && TCAccepted;
  useEffect(() => {
    const params = { channel: inApp ? 'App' : 'Web' };
    if (customer?.customerSessionToken?.pizzaExpressId) {
      params.customerId = customer.customerSessionToken.pizzaExpressId;
    }
    gtmEvent(isDelivery ? 'delivery_checkout_details' : 'collection_checkout_details', params);
    if (loyalty && appApproved
      && joined === 'joined'
      && currentTier?.toLowerCase() === 'signup'
      && rewards?.find(r => r.rewardDetails.zonalVariableDiscountCode === 306)
      && !discounts?.find(d => d.eposDiscountId === '306')) {
      setChangeWelcomeModalIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (TCError) {
      const ErrorElement = document.getElementById('details-tcerror');
      const y = ErrorElement.getBoundingClientRect().top + window.pageYOffset - 350;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [TCError]);

  const handleContinue = async () => {
    const { data } = await getTimeSlotsQuery(restaurant.id);
    if (!canProceed) {
      setTCError(true);
      return;
    }
    if (data.includes(collectionTime)) {
      if (isRecieveOffers)
        historyMW.push('/checkout/verifyemail', isDelivery, history);
      else
        historyMW.push('/payment', isDelivery, history);
    } else {
      setCollectionTimeError('Your time slot has expired. Please select another slot.');
    }
  };

  const handleJoin = () => {
    const params = { channel: inApp ? 'App' : 'Web' };
    if (customer?.customerSessionToken?.pizzaExpressId) {
      params.customerId = customer.customerSessionToken.pizzaExpressId;
    }
    gtmEvent(isDelivery ? 'delivery_join_club' : 'Collection_join_club', params);
    historyMW.push('/checkout/join', isDelivery, history);
  };

  const handleTCaccepted = (accepted) => {
    if (accepted) setTCError(false);
    setTCAccepted(accepted);
  };

  return (
    <Layout
      title="your contact"
      title2="details"
      isFluid={true}
      backgroundColour={inApp ? 'malbec-100' : 'transparent'}
      titleColour={inApp ? 'rose-100' : 'black'}
      heroPaddingSize={'is-medium'}
      section={`is-medium ${inApp ? 'pt-4' : 'is-spaced'} pb-6`}
      containerBreakpoint="desktop"
      backButton={appApproved ? () => historyMW.push('/loyalty', isDelivery, history) : () => historyMW.push('/basket', isDelivery, history)}
      isBasket
      isSmallerTitle
      customStyles='is-basket'
    >
      <WelcomeToPEModalWrapper
        changeWelcomeModalIsOpen={changeWelcomeModalIsOpen}
        setChangeWelcomeModalIsOpen={setChangeWelcomeModalIsOpen}
        onAddItem={onAddItem}
        inApp={inApp}
        navigateTo={navigateTo}
      />
      <div className={`details-content-wrapper ${inApp ? 'in-app' : ''}`} style={{ zIndex: 1 }}>
        <div className="level content is-mobile is-align-top">
          <div className="level-item is-growable is-shrinkable is-justify-content-flex-start">
            <div className="current-details" style={{ lineHeight: 2, marginBottom: 20 }}>
              {firstName}{' '}
              {lastName}
              <br />
              {email}
              <br />
              {mobileNumber}
              <br />
              {postCode}
              <br />
              {customer && loyalty && !appApproved &&
                <div className={'column is-12 form-field-wrapper'}>
                  <div id='loginOptInButton' onClick={() => setIsRecieveOffers(!isRecieveOffers)} style={{ margin: 16 }}>
                    <label className="switch is-flex is-justify-content-flex-start" style={{ alignItems: 'center', gap: 10 }}>
                      <img
                        style={{
                          width: 31.05, // this is needed to avoid any movement when radio button is selected
                          height: 32,
                          marginRight: 16,
                        }}
                        className="check"
                        src={isRecieveOffers ? checked : checkNotSelected}
                        alt="checked"
                      />
                      <div className="field-label">Join PizzaExpress club</div>
                    </label>
                  </div>
                  {
                    isRecieveOffers &&
                    <>
                      <GreenTickAndText
                        selected={TCAccepted}
                        setSelected={handleTCaccepted}
                        id='tandCButton'
                        error={isRecieveOffers && TCError}
                        disabled={!isRecieveOffers}
                      >
                        I have read and agree to the
                        <a className="link" style={{ margin: 4 }} href='https://www.pizzaexpress.com/terms-of-use' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>
                        of this website and
                        <a className="link" style={{ margin: 4 }} href='https://www.pizzaexpress.com/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
                      </GreenTickAndText>
                      {TCError && (
                        <div className="level is-mobile is-family-code" id='details-tcerror'>
                          <div className="level-item is-narrow is-shrinkable is-align-top">
                            <i className="icon is-small" >
                              <img src={errorIcon} alt="error" />
                            </i>
                          </div>
                          <div className="level-item is-growable is-shrinkable is-justify-content-flex-start is-align-top">
                            <div className='error-message'>You must agree to the Terms and Conditions to be able to continue.</div>
                          </div>
                        </div>
                      )}
                    </>
                  }
                </div>
              }
            </div>
          </div>
          <div className="level-item is-narrow detail-edit">
            <p className='edit-btn is-pointer'
              onClick={() => historyMW.push('/checkout/updateDetails', isDelivery, history)}>Edit</p>
          </div>
        </div>
      </div >

      {!customer && loyalty && !appApproved && (
        <div className="column is-12 mb-5" style={{ textAlign: 'center' }}>
          <button
            id='joinButton'
            style={{ color: 'white' }}
            className="btn btn-primary w10 is-text-weight-bold"
            onClick={handleJoin}>
            {'Join PizzaExpress Club'}
          </button>
        </div>
      )}
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className='details-divider' />
      </div>
      {
        isDelivery
          ? <DeliveryDetails isRecieveOffers={isRecieveOffers} canProceed={canProceed} setTCError={setTCError} deliveryAddress={deliveryAddress}
          />
          : <>
            <SimpleHero title="collection time" customClass={`collection-time-title ${inApp ? 'in-app' : ''}`} />
            <div className="signup-content-wrapper">
                <CollectionTime fullWidth hideTitle customClass='details-collection-time' inSignupForm={true} error={collectionTimeError} setError={setCollectionTimeError} />
              <div className="column is-12" style={{ textAlign: 'center' }}>
                <div id='continueButton' onClick={handleContinue} className="btn btn-primary w10" style={{ margin: 'auto' }}>Continue to payment</div>
              </div>
            </div>
          </>
      }
    </Layout>
  );
};

export default Details;
