import React from 'react';
import Close from '../../img/icons/close.svg';

export const LeaveOOPModal = ({ onClose, leaveOOPHandler }) => (
    <div className={`change-restaurant-modal-container`}>
        <div className="change-restaurant-modal-content">
            <div className="close-icon-wrapper" >
                <i className={'icon is-large'} style={{ cursor: 'pointer' }} >
                    <img src={Close} alt="close" onClick={onClose} />
                </i>
            </div>
            <h1 className='title'>Are you sure?</h1>
            <p className="info">Leaving this page will result in you losing the contents of your basket.</p>
            <div className="button-wrapper">
                <button
                    id="leave"
                    onClick={() => {
                        onClose();
                        leaveOOPHandler();
                    }}
                    className="btn btn-secondary restaurant-stay-btn"
                >
                    Continue
                </button>
                <button
                    id="stay"
                    onClick={onClose}
                    className="btn btn-primary restaurant-stay-btn"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
);