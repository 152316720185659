import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import PasswordField from '../../../components/PasswordField';
import Section from '../../../components/Section';
import Layout from '../../../components/Layout';
import PasswordChecker from '../components/PasswordChecker';
import { useResetPasswordMutation, useValidatePasswordResetMutation } from '../../../services/customer.api';
import { useLazyGetApplicationStateQuery } from '../../../services/application.api';
import { historyMW } from '../../../helpers/routingHelpers';
import { useSelector } from 'react-redux';
import { freshRelevance } from '../../../helpers/commonHelpers';

const ResetPassword = () => {
  const [getApplicationState, { isGettingApplicationState }] = useLazyGetApplicationStateQuery();
  const [tokenState, setTokenState] = useState('UNKNOWN');
  const [inputValues, setInputValues] = useState({ password: '' });
  const [inputErrors, setInputErrors] = useState({ password: '' });
  const [validatePassword, { isValidatingPassword }] = useValidatePasswordResetMutation();
  const [resetPassword, { isResettingPassword }] = useResetPasswordMutation();
  const history = useHistory();
  const params = useParams();
  const { state, token } = params;
  const { isDelivery } = useSelector(state => state.basket);
  const { inApp } = useSelector(state => state.session);

  useEffect(() => {
    const getData = async () => {
      const result = await validatePassword({ token, state });
      if (!result.data.success) {
        setTokenState('INVALID');
      } else {
        setTokenState('VALID');
      }
    };
    getData();
  }, []);

  const handleChange = useCallback((name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const handleIsResetSent = async (e) => {
    e.preventDefault();

    if (inputValues.password === '' || inputErrors.password !== '') {
      setInputErrors({ password: 'Please enter a valid password' });
    } else {
      let response = await validatePassword({
        token,
        state,
      });
      if (response.data.success) {
        response = await resetPassword({
          token,
          password: inputValues.password,
          state
        });
        if (response.data.success) {
          freshRelevance('pageChange', null, {'sendBeacon': true});
          await getApplicationState();
          historyMW.replace('/passwordResetConfirmation', isDelivery, history);
        } else {
          setTokenState('INVALID');
        }
      }
    }
  };

  const handleForgottenPassword = async () => {
    historyMW.replace('/forgottenPassword', isDelivery, history);
  };

  return (
    <Layout 
      title='Change your password'
      titleColour={inApp ? 'rose-100' : 'white'}
      noImage
    >
      <div style={{ width: 500, maxWidth: '100%', margin: 'auto', position: 'relative', bottom: 40 }}>
        {tokenState === 'VALID' &&
          <Section>
            <form onSubmit={handleIsResetSent} className="column is-12">
              <div>
                <PasswordField
                  title="Password"
                  name="password"
                  value={inputValues.password}
                  valueCallback={handleChange}
                  error={inputErrors.password}
                  errorCallback={handleError}
                  required={true}
                  id='passwordInput'
                  maxWidth
                />
              </div>
              <div className="column is-12">
                <PasswordChecker value={inputValues.password} />
                <div className="block has-text-centered">
                  <input
                    type="submit"
                    disabled={isValidatingPassword || isResettingPassword || isGettingApplicationState || !inputValues.password.length > 7
                      || inputValues.password.toLocaleUpperCase() === inputValues.password
                      || inputValues.password.toLocaleLowerCase() === inputValues.password
                      || !/\d/.test(inputValues.password)}
                    className={`btn btn-primary w10 ${inputValues.length > 0 ? '' : 'disabled'}`}
                    id='changeYourPasswordButton'
                    value="Change your password"
                  />
                </div>
              </div>
            </form>
          </Section>}
        {tokenState === 'INVALID' && (
          <Section>
            <div className="column is-12">
              <div>
                <div className="block has-text-centered">
                  <p className="pb-6">Sorry, the reset password link you have used has expired.  Click below to request a new one.</p>
                  <button
                    onClick={handleForgottenPassword}
                    disabled={false}
                    className={`btn btn-primary w10 ${inputValues.length > 0 ? '' : 'disabled'}`}
                    id='changeYourPasswordButton'
                  >
                    Reset your password
                  </button>
                </div>
              </div>
            </div>
          </Section>
        )}
      </div>
    </Layout>
  );
};

export default ResetPassword;
