import React, { useEffect, useState } from 'react';
import Cross from '../shared/Cross';
import Tick from '../../img/menu/tickIcon.svg';
import unticked from '../../img/icons/unchecked.svg';
import GlutenFree from '../../img/icons/dietary/Gluten-Free.svg';
import Veg from '../../img/icons/dietary/Vegetarian.svg';
import Vegan from '../../img/icons/dietary/Vegan.svg';
import { updateFilters } from '../../store/menuSlice';
import { useDispatch, useSelector } from 'react-redux';


const RewardImage = (symbol) => {
  switch (symbol) {
    case 'GF':
      return GlutenFree;
    case 'V':
      return Veg;
    case 'VE':
      return Vegan;
  }
};

export const FilterButton = ({ onClick, selected, title, symbol, inApp }) => (
  <div className="filter-type-selector block mb-2"
    style={inApp ? { backgroundColor: '#fff', height: '4.25rem', fontSize: '1rem', display: 'flex', alignItems: 'center' } : {}}
  >
    <div
      className="is-flex is-align-content-center is-justify-content-space-between is-radius-medium is-clickable w100 dietary-card"
      onClick={onClick}
    >
      <div className="is-flex is-align-content-center">
        {
          selected ?
            <img
              className={'dietary-card-icon'}
              src={Tick}
              alt="Tick button"
            />
            :
            <img
              className={'dietary-card-icon'}
              src={unticked}
              alt="Tick button"
            />
        }
        <p className="diet-type-text my-a" style={{ paddingLeft: '1rem', fontSize: inApp ? '18px' : '20px' }}>{title}</p>
      </div>
      <div className="is-flex is-align-content-center">
        <div className="icon is-medium">
          <img style={{ width: '32px', height: '32px' }} src={RewardImage(symbol)} />
        </div>
      </div>
    </div>
  </div>
);

const DietaryFilterModal = ({ onClose, inApp }) => {
  const filters = useSelector((state) => state.menu.filters);
  const [localFilters, setLocalFilters] = useState(filters);
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleFilterChange = (filter) => {
    setLocalFilters((currentFilters) =>
      currentFilters.includes(filter)
        ? currentFilters.filter((f) => f !== filter)
        : [...currentFilters, filter]
    );
  }
  const handleConfirm = () => {
    dispatch(updateFilters(localFilters));
    onClose();
  };

  return (
    <div
      className={`filter-modal pb-0 ${inApp ? 'pt-4' : ''}`}
      style={inApp ? { textAlign: 'center' } : { paddingTop: '4rem' }}
    >
      {!inApp &&
        <Cross onClick={onClose} absolute rightPosition={-10} topPosition={-2} />
      }
      <div className={'content w100'}>
        <h3 className="title" style={inApp ? { textTransform: 'uppercase' } : {}}>Filter by diet</h3>
        <div className="subtitle block">
          <p
            style={inApp ? { color: '#000', marginBottom: '2rem' } : {}}
          >
            Click to filter the menu by dietary requirement and remember to let
            our team know when you order.
          </p>
        </div>
        <FilterButton
          onClick={() => handleFilterChange('Gluten-Free')}
          selected={localFilters.includes('Gluten-Free')}
          title="Gluten-Free"
          symbol="GF"
          inApp={inApp}
        />
        <FilterButton
          onClick={() => handleFilterChange('Vegetarian')}
          selected={localFilters.includes('Vegetarian')}
          title="Vegetarian"
          symbol="V"
          inApp={inApp}
        />
        <FilterButton
          onClick={() => handleFilterChange('Vegan')}
          selected={localFilters.includes('Vegan')}
          title="Vegan"
          symbol="VE"
          inApp={inApp}
        />
        <div className="block pt-5">
          <button
            id="confirm"
            className="btn btn-primary confirm-btn"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
        <div className="block" style={{ marginBottom: inApp ? '' : '1rem' }}>
          <div
            id="clearSelection"
            onClick={() => {
              dispatch(updateFilters([]));
              setLocalFilters([]);
            }}
            className="clear-btn-wrapper"
          >
            <p className="clear-btn" style={{ marginTop: 16 }}>Clear selection</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DietaryFilterModal;
