import React, { useEffect, useState, useRef } from 'react';
import braintree from 'braintree-web';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { paymentTypes } from '../../../contants/paymentType';
import { postMessageWithAwait } from '../../../helpers/appHelpers';

const PayByApplePay = ({
  clientToken,
  grandTotal,
  generatePaymentBody,
  paymentSuccessful,
  submitPaymentFail,
  currentlySubmittingPayment,
  pay,
  inApp,
  customer,
  supportedPaymentMethods,
  setDisablePaymentBackButton
}) => {
  const [canMakePaymentsWithActiveCard, setCanMakePaymentsWithActiveCard] = useState(false);
  const [finishedLoadingApplePay, setFinishedLoadingApplePay] = useState(false);
  const generatePaymentBodyRef = useRef(null);
  generatePaymentBodyRef.current = generatePaymentBody;
  useEffect(() => {
    const renderButton = async () => {
      let canRender = false;
      const ApplePaySession = window.ApplePaySession;
      try {

        if (!!window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()) {
          canRender = true;
          // This device supports version 3 of Apple Pay.
        }
        if (!ApplePaySession) {
          console.error('This device does not support Apple Pay');
        }
        if (!ApplePaySession.canMakePayments()) {
          console.error('This device is not capable of making Apple Pay payments');
        }
      } catch (err) {
        console.error(err.message);
      }
      if (canRender || supportedPaymentMethods?.applePay) {
        try {
          let clientInstance;
          let applePayInstance;
          if (!inApp) {
            clientInstance = await braintree.client.create({ authorization: clientToken });
            applePayInstance = await braintree.applePay.create({ client: clientInstance });
            await ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier);
            setCanMakePaymentsWithActiveCard(true);
            setFinishedLoadingApplePay(true);

            var paymentRequest = applePayInstance.createPaymentRequest({
              total: {
                label: 'Pizza Express',
                amount: grandTotal
              },
              requiredShippingContactFields: ['email'],

              // We recommend collecting billing address information, at minimum
              // billing postal code, and passing that billing postal code with
              // all Apple Pay transactions as a best practice.
              requiredBillingContactFields: ['postalAddress']
            });
          } else if (inApp && supportedPaymentMethods?.applePay) {
            setFinishedLoadingApplePay(true);
            setCanMakePaymentsWithActiveCard(true);
          }
          const button = document.getElementById('apple-pay-button');

          button.addEventListener('click', async event => {
            const params = { channel: inApp ? 'App' : 'Web' };
            if (customer?.customerSessionToken?.pizzaExpressId) {
              params.customerId = customer.customerSessionToken.pizzaExpressId;
            }
            gtmEvent('applepay_click', params);
            event.preventDefault();
            if (currentlySubmittingPayment) return;
            if (inApp) {
              const payData = {
                clientToken,
                merchantIdentifier: 'merchant.pizzaexpress.paymybill2022',
                countryCode: 'GB',
                currencyCode: 'GBP',
                merchantName: 'Pizza Express',
                orderTotal: grandTotal,
                applePay: true,
                googlePay: false,
                vaultManager: false,
                payPal: false,
                cardDisabled: true,
                darkTheme: true,
              }
              try {
                const result = await postMessageWithAwait(
                  { type: 'APPLE_PAY_START', payload: payData },
                  ['APPLE_PAY_TOKEN', 'PAYMENT_FAILURE']
                );
                if (!result || result.type === 'PAYMENT_FAILURE') throw new Error(result.error);
                setDisablePaymentBackButton(true);
                const response = await pay(generatePaymentBodyRef.current(result.payload, paymentTypes.Apple));
                const data = response.data || response.error?.data;
                if (data?.status !== 'OK') {
                  submitPaymentFail(data);           
                } else {
                  freshRelevance('pageChange', null, { 'sendBeacon': true });
                  paymentSuccessful(data);
                }
              } catch (err) {
                console.error(err);
                if (!err.message?.includes('User closed the Payment Request UI.')) {
                  submitPaymentFail();
                }
                setDisablePaymentBackButton(false);
              }
            } else {
              setDisablePaymentBackButton(true);
              var session = new ApplePaySession(3, paymentRequest);

              session.onvalidatemerchant = async event => {
                try {
                  const merchantSession = await applePayInstance.performValidation({ validationURL: event.validationURL, displayName: 'Click and Collect' });
                  await session.completeMerchantValidation(merchantSession);
                } catch (validationErr) {
                  // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                  console.error('Error validating merchant:', validationErr);
                  session.abort();
                }
              };

              session.onpaymentauthorized = async event => {
                try {
                  const payload = await applePayInstance.tokenize({ token: event.payment.token });
                  const response = await pay(generatePaymentBodyRef.current(payload, paymentTypes.Apple));
                  const data = response.data || response.error?.data;

                  if (data?.status !== 'OK') {
                    submitPaymentFail(data);
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                  } else {
                    freshRelevance('pageChange', null, { 'sendBeacon': true });
                    paymentSuccessful(data);
                    session.completePayment(ApplePaySession.STATUS_SUCCESS);
                  }

                  // After you have transacted with the payload.nonce,
                  // call `completePayment` to dismiss the Apple Pay sheet.
                } catch (tokenizeErr) {
                  console.error('Error tokenizing Apple Pay:', tokenizeErr);
                  session.completePayment(ApplePaySession.STATUS_FAILURE);
                }
              };

              session.begin();
            }
          });
        } catch (error) {
          setCanMakePaymentsWithActiveCard(false);
          setFinishedLoadingApplePay(true);
        }
      }
    };
    renderButton();
  }, []);
  
  return (
    <div style={currentlySubmittingPayment ? { pointerEvents: 'none' } : {}}>
      <div
        style={finishedLoadingApplePay === false || canMakePaymentsWithActiveCard === false
          ? { visibility: 'hidden' }
          : { margin: 0, display: 'block', height: 48, with: 200 }}
        id="apple-pay-button"
        className="apple-pay-button-with-text apple-pay-button-black-with-text">
        <span className="text">Pay with</span>
        <span className="logo"></span>
      </div>
      {finishedLoadingApplePay && !canMakePaymentsWithActiveCard && (
        <div>
          <p>
            Your browser or device does not support Apple Pay on the web.
          </p>
        </div>
      )}
    </div>
  );
};

export default PayByApplePay;