import React, { useState, useRef, useEffect } from 'react';
import { Container } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import pelogo from './../img/pelogos/peLogo.svg';
import milogo from './../img/pelogos/miLogo.png';
import shoppingBasket from './../img/icons/shoppingBasket.svg';
import reward from '../img/menu/reward.svg';
import { historyMW, pathMW } from '../helpers/routingHelpers';
import { Link } from 'react-router-dom';
import { setCheckoutCompletePath, setNavMenuHeight } from '../store/sessionSlice';
import { config } from '../config.js';
import { valueWithAppliedRewards } from '../features/checkout/components/DeliveryDetails';
import ChevronLeft from '../img/icons/ChevronLeft.svg';
import close from '../img/icons/close.svg';
import { LeaveOOPModalWrapper } from '../helpers/modalHelpers';
import { useSendToHome } from '../hooks/useSendToHome';
import { updateBasket } from '../store/basketSlice';

const brand = config.BRAND;

const NavMenu = ({
  showLoginBanner,
  height,
  setHeight,
  onLogOut,
  inApp,
  handleCloseIframe,
  leaveOOPHandler,
  disablePaymentBackButton,
  offHomePage
}) => {
  const { customer, appInsets } = useSelector(state => state.session);

  const { deliveryLandingPage } = useSelector(state => state.session.features);
  const { isDelivery, discounts, numberOfItems, voucherReduction, total, subTotal } = useSelector(state => state.basket);
  const restaurantId = useSelector(state => state.session?.restaurant?.id);
  const path = window.location.pathname?.toLowerCase();
  const wrapperRef = useRef('menu');
  const dropDownRef = useRef('dropDown');
  const [isMenuActive, setMenuActive] = useState(false);
  const [isDropDownActive, setDropDownActive] = useState(false);
  const [localDisablePaymentBackButton, setLocalDisablePaymentBackButton] = useState(false);
  const history = useHistory();
  const historyPathname = history?.location?.pathname;
  const [navMenuRef, setNavMenuRef] = useState();
  const { search } = useLocation();
  const [orderId, setOrderId] = useState(null);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  useEffect(() => {
    const height = navMenuRef?.offsetHeight;
    dispatch(setNavMenuHeight(appInsets?.top ? (height - 20) - appInsets.top / 4 : height));
  }, [navMenuRef?.offsetHeight])

  useEffect(() => {
    const handleUpdateNavBar = (event) => {
      setShowCloseIcon(event.detail.showClose);
    };

    window.addEventListener('updateNavBar', handleUpdateNavBar);

    return () => {
      window.removeEventListener('updateNavBar', handleUpdateNavBar);
    };
  }, []);


  useEffect(() => {
    const handleUpdateNavBar = (event) => {
      setShowCloseIcon(event.detail.showClose);
    };

    window.addEventListener('updateNavBar', handleUpdateNavBar);

    return () => {
      window.removeEventListener('updateNavBar', handleUpdateNavBar);
    };
  }, []);


  useEffect(() => {
    const getOrderIdParam = () => {
      //orderid could be null, or search undefined
      const query = new URLSearchParams(search);
      const orderId = query.get('ord');
      setOrderId(orderId);
    };

    getOrderIdParam();
  }, [search]);

  const dispatch = useDispatch();
  useEffect(() => {
    document.addEventListener('click', handleMenuClickOutside, true);
    return () => {
      document.removeEventListener('click', handleMenuClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setLocalDisablePaymentBackButton(disablePaymentBackButton);
  }, [disablePaymentBackButton]);

  const handleMenuClickOutside = event => {
    if (isMenuActive && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setMenuActive(!isMenuActive);
    }
    if (isDropDownActive && dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setDropDownActive(!isDropDownActive);
    }
  };

  const handleLogoClick = () => {
    if (!deliveryLandingPage && path == '/delivery') leaveOOPHandler('https://pizzaexpress.com/delivery');
    else leaveOOPHandler(brand === 'PE' ? 'https://www.pizzaexpress.com' : 'https://www.milano.ie');
  };

  useEffect(() => {
    if (wrapperRef && height !== wrapperRef.current.clientHeight) {
      setHeight(wrapperRef.current.clientHeight);
    }
  }, [wrapperRef.current.clientHeight]);

  const handleCloseInApp = (e) => {
    e.stopPropagation();
    handleCloseIframe();
  };

  const showBasket = numberOfItems > 0 && path !== '/' && path !== '/collection' && path !== '/delivery' && !path.endsWith('order/confirmation');

  const menuUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/menu\\/${restaurantId}`);
  const customiseUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/menu\\/${restaurantId}\\/customise/[\\w/%20]*`);
  const bundleUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/menu\\/${restaurantId}\\/bundle/[\\w/%20]*`);
  const basketUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/basket`);
  const loyaltyUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/loyalty`);
  const detailsUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/checkout\\/details`);
  const updateDetailsUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/checkout\\/updateDetails`);
  const paymentUrlRegex = new RegExp(`\\/${isDelivery ? 'delivery' : 'collection'}\\/payment`);

  const displayBackButtonInApp = () => {
    const willDisplay = menuUrlRegex.test(historyPathname) ||
      customiseUrlRegex.test(historyPathname) ||
      bundleUrlRegex.test(historyPathname) ||
      basketUrlRegex.test(historyPathname) ||
      loyaltyUrlRegex.test(historyPathname) ||
      detailsUrlRegex.test(historyPathname) ||
      updateDetailsUrlRegex.test(historyPathname) ||
      paymentUrlRegex.test(historyPathname);
    return willDisplay;
  };

  const handleBackButtonClicked = async () => {
    // Order of the cases is important
    switch (true) {
      case basketUrlRegex.test(historyPathname):
        historyMW.push(`/menu/${restaurantId}`, isDelivery, history);
        break;
      case customiseUrlRegex.test(historyPathname):
      case bundleUrlRegex.test(historyPathname):
        history.goBack();
        break;
      case menuUrlRegex.test(historyPathname):
        historyMW.push('', isDelivery, history);
        break;
      case loyaltyUrlRegex.test(historyPathname): {
        const rewardsResponse = await fetch(`/basket/GetRewardsForTransientBasket/${!inApp}`);
        const rewards = await rewardsResponse.json();
        historyMW.push('/basket', isDelivery, history);
        if (rewards.updateBasketResponse) {
          dispatch(updateBasket(rewards.updateBasketResponse));
        }
        break;
      }
      case detailsUrlRegex.test(historyPathname):
        historyMW.push('/loyalty', isDelivery, history);
        break;
      case updateDetailsUrlRegex.test(historyPathname):
      case paymentUrlRegex.test(historyPathname):
        historyMW.push('/checkout/details', isDelivery, history);
        break;
      default:
        history.goBack();
    }
  };

  const getDeliveryTotal = (subTotal, discounts) => {
    let result = subTotal;
    const deliveryFeeDiscount = discounts.find(d => d.offerTitle === "Free Delivery");
    if (deliveryFeeDiscount) result -= deliveryFeeDiscount.amount;
    return result.toFixed(2);
  }

  return (
    <>
      <div ref={setNavMenuRef} className="is-flex is-flex-direction-column" style={{ zIndex: 1000, position: 'fixed', top: appInsets?.top ? `-${appInsets.top / 4}px` : '0', left: '0px', right: '0' }} >
        <nav ref={wrapperRef} style={{ zIndex: 3 }} role="navigation" aria-label="main navigation">
          <Container className={'is-fluid has-background-primary-color'} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={`navbar ordered is-flex-wrap-nowrap has-background-primary-color ${inApp ? 'in-app' : ''}`} style={{ width: '100%', maxWidth: 1440 }}>
              {inApp ? displayBackButtonInApp() ?
                <div className={`navbar-brand ${inApp ? 'in-app' : ''}`}>
                  <div
                    style={{ color: `${localDisablePaymentBackButton ? '#dfdad4' : 'white'}`, display: 'flex', alignItems: 'center', gap: 10 }}
                    onClick={!localDisablePaymentBackButton ? handleBackButtonClicked : undefined}
                    aria-disabled={localDisablePaymentBackButton}
                  >
                    <img
                      src={ChevronLeft}
                      className='invert'
                      style={{ height: 22, opacity: `${localDisablePaymentBackButton ? 0.7 : 1}` }}
                    />
                    Back
                  </div>
                </div>
                :
                <div className="navbar-brand"></div>
                :
                <div className="navbar-brand">
                  <div onClick={handleLogoClick} style={{ cursor: 'pointer' }} >
                    <figure className="brand">
                      {brand === 'PE' ?
                        <img src={pelogo} alt='logo' />
                        : <img src={milogo} alt='logo' />
                      }
                    </figure>
                  </div>
                </div>
              }

              <div className={`navbar-cta order-3 ml-4-desktop ${inApp ? 'in-app' : ''}`} style={{ height: '100px' }}>
                {
                  customer && !inApp
                    ?
                    <button className={'logout-btn btn btn-primary'} onClick={onLogOut}>Log out</button>
                    : null
                }
                <span
                  className={`basket-wrapper ${showBasket ? 'show-basket' : ''}`}
                  onClick={() => showBasket && historyMW.push('/basket', isDelivery, history)}
                >
                  {orderId || path.includes('/order/confirmation') ? <></> :
                    <img onClick={() => showBasket && historyMW.push('/basket', isDelivery, history)} style={{ cursor: showBasket ? 'pointer' : 'default' }} src={shoppingBasket} alt="basket" />
                  }
                  {
                    showBasket ?
                      (<div
                        onClick={() => historyMW.push('/basket', isDelivery, history)}
                        className="item-quantity"
                      >
                        <p>{numberOfItems}</p>
                      </div>
                      ) : null
                  }
                  {
                    showBasket &&
                    <p className='basket-value mr-3'>
                      {
                        (path.includes('checkout') && !path.includes('login')) || path.includes('payment') ?
                          `${config.CURRENCY_SYMBOL}${total.toFixed(2)}` :

                          (path.includes('delivery') && path.includes('menu') || inApp) ?
                            `${config.CURRENCY_SYMBOL}${getDeliveryTotal(subTotal, discounts)}` :
                            `${config.CURRENCY_SYMBOL}${valueWithAppliedRewards(subTotal - voucherReduction, discounts).toFixed(2)}`
                      }
                    </p>
                  }
                  {
                    inApp && (showCloseIcon || offHomePage) &&
                    <img onClick={handleCloseInApp} className='invert' style={{ height: 48, marginLeft: 10 }} src={close} />
                  }

                </span>
              </div>
            </div>
          </Container>
        </nav>
        {showLoginBanner && (
          <div
            className="login-banner px-5 py-2 zi-2 is-justify-content-center is-align-items-center is-flex"
          >
            <img src={reward} className="mr-2" />
            <p>
              <Link to={pathMW('/checkout/login?menu=true', isDelivery)} onClick={(() => dispatch(setCheckoutCompletePath(path)))} id='logInLink'>Log in</Link> to claim your PizzaExpress Club Rewards. Not a member? <Link onClick={() => dispatch(setCheckoutCompletePath(path))} to={pathMW('/checkout/join/true', isDelivery)} id='JoinLink'>Join Now</Link>
            </p>
          </div>
        )}
        <div id='notificationContainer' />
      </div>
      <LeaveOOPModalWrapper />
    </>
  );
};

export default NavMenu;
