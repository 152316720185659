import React, { useRef, useState, useEffect } from 'react';
import icon from '../img/icons/question.svg';

const InfoPopOver = ({ text, parentRef = { current: {} }, id, invert = false, maxWidth = 388}) => {
  const [offset, setOffset] = useState([-17, -28]);
  const [visible, setVisible] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const imageRef = useRef({ current: {} });
  const tippyDebounceDuration = 450; //ms


  const handleClick = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setVisible(!visible);
    }, tippyDebounceDuration);
  };
  const handleMouseOver = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setVisible(true);
    }, tippyDebounceDuration);
  };
  const handleMouseOut = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setVisible(false);
    }, tippyDebounceDuration);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div style={{display: "inline-block", position: 'relative'}}>
      <div
        style={{
          pointerEvents: 'auto',
          zIndex: '9999',
          display: `${visible ? 'block' : 'none'}`,
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          margin: '0px',
          transform: `translate(-${parentRef.current?.offsetWidth - 20}px, -28px)`
        }}
      >
        <div
          className="tippy-box delivery-details-tippy"
          tabIndex="-1"
          role="tooltip"
          style={{maxWidth: `calc(${maxWidth}px - 40px)`, width: '334px',transitionDuration: '300ms', padding: '1rem 0.5rem 0 0.5rem'}}
        >
          <div
            className="tippy-content dm-sans"
            data-state="visible"
            style={{transitionDuration: '300ms'}}
          >
            <div>
              <p>{text}</p>
            </div>
          </div>
          <div
            className="tippy-arrow"
            style={{
              position: 'relative',
              transform: `translate(${parentRef.current?.offsetWidth - 29}px, 4px) rotate(45deg)`,
            }}
          ></div>
        </div>
      </div>
      <img ref={imageRef} id='upperAndLowerIndicator' className={invert ? 'invert' : null} style={{marginBottom: '-2px'}} src={icon} alt="info" onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}/>
    </div>
  );
};

export default InfoPopOver;