import { React, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import exclamationMark from '../../img/icons/exclamationMark.svg';
import { DraggableModal } from './DraggableModal';

const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const stylesOutOfStockModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    borderRadius: 10,
    maxWidth: '600px'
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.2)',
    zIndex: 1000
  }
};

const OutOfStockModal = ({
  onRemovedItems
}) => {
  const inApp = useSelector(state => state.session.inApp);
  const basket = useSelector(state => state.basket);
  const outOfStockItems = [
    ...basket?.outOfStockItems || [],
    ...basket?.unavailableItems || []
  ];
  const { pathname } = useLocation();
  const outOfStockBundles = basket?.outOfStockBundles || [];
  const outOfStockIngredients = basket?.outOfStockIngredients || [];
  const distinctOutOfStockItems = Object.values(groupBy(outOfStockItems, 'id')).map(group => group[0]);
  const distinctOutOfStockIngredients = Object.values(groupBy(outOfStockIngredients, 'id')).map(group => group[0]);
  const distinctBundles = Object.values(groupBy(outOfStockBundles, 'name')).map(group => group[0]);
  const [outOfStockModalOpen, setOutOfStockModalOpen] = useState(false);

  useEffect(() => {
      setOutOfStockModalOpen(outOfStockItems.length > 0);
  }, [outOfStockItems.length, pathname]);

  const handleRemoveItems = async () => {
    if (onRemovedItems) {
      onRemovedItems();
    }
  };

  if (inApp) return (
    <DraggableModal
      show={outOfStockModalOpen}
      onClose={onRemovedItems}
      style={{ padding: '0.5rem 1.25rem 4rem' }}
    >
      <div className="filter-modal is-text-center is-relative is-flex is-justify-content-center pt-4 pb-4">
        <div className={'content w100'}>
          <img src={exclamationMark} style={{ width: 120, marginTop: inApp ? '1rem' : '' }} />
          <h3 className="title is-3">OUT OF STOCK OR UNAVAILABLE</h3>
          <div className="block">
            {distinctOutOfStockIngredients.length > 0 && (
              <>
                <p>The following extras are out of stock:</p>
                <p>
                  {distinctOutOfStockIngredients.map((item, i) => {
                    return (<span key={i}><strong>{item.name}</strong><br /></span>);
                  })}
                </p>
              </>
            )}
            <p>
              To continue, please remove the following items from your basket.
            </p>
            {distinctOutOfStockItems.length > 0 && (
              <>
                <p>
                  {distinctOutOfStockItems.map((item, i) => {
                    return (<span key={i}><strong>{item.name}</strong><br /></span>);
                  })}
                  {distinctBundles.map((item, i) => {
                    return (<span key={i}><strong>{item.name}</strong><br /></span>);
                  })}
                </p>
              </>
            )}
          </div>
          <div className="block pt-4">
            <button
              id="confirm"
              className="btn btn-primary w100"
              onClick={handleRemoveItems}
            >
              Check Basket
            </button>
          </div>
        </div>
      </div>
    </DraggableModal>
  );

  return (

    <Modal
      isOpen={outOfStockModalOpen}
      onRequestClose={handleRemoveItems}
      style={stylesOutOfStockModal}
      ariaHideApp={false}
    >
      <div className="filter-modal is-text-center is-relative is-flex is-justify-content-center pt-6 pb-4">
        <div className={'content w100'}>
          <h3 className="title is-3">Out of Stock or Unavailable</h3>
          <div className="block">
            {distinctOutOfStockIngredients.length > 0 && (
              <>
                <p>The following extras are out of stock:</p>
                <p>
                  {distinctOutOfStockIngredients.map((item, i) => {
                    return (<span key={i}><strong>{item.name}</strong><br /></span>);
                  })}
                </p>
              </>
            )}
            <p>
              To continue, please remove the following items from your basket.
            </p>
            {distinctOutOfStockItems.length > 0 && (
              <>
                <p>
                  {distinctOutOfStockItems.map((item, i) => {
                    return (<span key={i}><strong>{item.name}</strong><br /></span>);
                  })}
                  {distinctBundles.map((item, i) => {
                    return (<span key={i}><strong>{item.name}</strong><br /></span>);
                  })}
                </p>
              </>
            )}
          </div>
          <div className="block pt-4">
            <button
              id="confirm"
              className="btn btn-primary w100"
              onClick={handleRemoveItems}
            >
              Check Basket
            </button>
          </div>
        </div>
      </div>
    </Modal >
  );
};

export default OutOfStockModal;