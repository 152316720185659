import React, { useEffect } from 'react';
import { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSafeState } from '../../../hooks/useIsMounted';

let timeout;

const Notification = ({ notification, setNotification }) => {

  const [numberOfItems, setNumberOfItems] = useSafeState(1);
  const [prevItem, setPrevItem] = useSafeState();
  const [domNode, setDomNode] = useSafeState();
  const reset = () => {
    setNumberOfItems(1);
    setPrevItem(false);
    clearTimeout(timeout);
    setNotification(false);
  };

  useLayoutEffect(() => {
    setDomNode(document.getElementById('notificationContainer'));
  }, []);

  useEffect(() => {
    if (timeout && notification.item === prevItem) {
      clearTimeout(timeout);
      setNumberOfItems(num => num += 1);
    } else if (timeout) {
      clearTimeout(timeout);
      setPrevItem(notification.item);
      setNumberOfItems(1);
    } else {
      setPrevItem(notification.item);
    }
    timeout = setTimeout(reset, 3000);
  }, [notification]);

  if (domNode) {
    return createPortal(
      notification.visible && notification.item.name ?
        <div className="notification" >
          <div
            className='block has-slide-in-animation'
            style={{
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <p style={{ textAlign: 'center' }}>
              {`${numberOfItems} X ${notification.item.name} has been added to the basket.`}
            </p>
          </div>
        </div> :
        <div></div>
      ,
      domNode
    );
  } else {
    return null;
  }


};

export default Notification;