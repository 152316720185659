import { createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../helpers/commonHelpers';
import { applicationApi } from '../services/application.api';
import { basketApi } from '../services/basket.api';
import { braintreeApi } from '../services/braintree.api';
import { customerApi } from '../services/customer.api';
import { orderApi } from '../services/order.api';

export const updateSignedInBasketUser = (state, basket) => {
  if (basket.version >= state.version) {
    const customersLength = basket.customers?.length - 1;
    state.email = basket.customers?.length > 0 ? basket.customers[customersLength].email : null;
    state.firstName = basket.customers?.length > 0 ? basket.customers[customersLength].firstName : null;
    state.lastName = basket.customers?.length > 0 ? basket.customers[customersLength].lastName : null;
    state.mobileNumber = basket.customers?.length > 0 ? basket.customers[customersLength].mobileNumber : null;
    state.title = basket.customers?.length > 0 ? basket.customers[customersLength].title : null;
    state.dateOfBirth = basket.customers?.length > 0 ? basket.customers[customersLength].dateOfBirth : null;
    state.postCode = basket.customers?.length > 0 ? basket.customers[customersLength].postcode : null;
    state.optIn = basket.customers?.length > 0 ? basket.customers[customersLength].optIn : null;
    state.version = basket.version;
  }
};

export const updateOutOfStockState = (state, basket) => {
  if (basket.version >= state.version) {
    state.outOfStockItems = [...basket.outOfStockItems || []];
    state.outOfStockBundles = [...basket.outOfStockBundles || []];
    state.outOfStockItemsInSession = [...state.outOfStockItemsInSession, ...state.outOfStockItems];
    state.outOfStockIngredients = [...basket.outOfStockIngredients || []];
    state.version = basket.version;
  }
};

export const commonUpdateBasketState = (state, basket, id, voucherExceptions = []) => {
  const { items, bundles, itemSubTotal: subTotal, voucherCodes, voucherReduction, totalCost: total, version, key, restaurantDistance } = basket;
  if (key != state.key || version >= state.version) {
    state.items = items;
    state.unavailableItems = items.filter(i => i.doesNotExistInCurrentMenu);
    state.bundles = bundles;
    state.numberOfItems =
      state.items
        .map(i => i.quantity).reduce((a, b) => a + b, 0) +
      state.bundles
        .map(i => i.quantity).reduce((a, b) => a + b, 0);
    state.subTotal = subTotal;
    state.id = id;
    state.voucherCodes = voucherCodes;
    state.voucherReduction = voucherReduction;
    state.total = total;
    state.version = version;
    state.delivery = basket.delivery;
    state.isDelivery = !!basket.delivery;
    state.deliveryFee = basket.deliveryFee;
    state.serviceFee = basket.serviceFee;
    state.discounts = basket.discounts;
    state.discountReduction = basket.discountReduction;
    state.smallOrderFee = basket.smallOrderFee;
    state.key = key;
    state.restaurantDistance = restaurantDistance;
    state.voucherExceptions = voucherExceptions;
    if (voucherExceptions.length) state.hasSeenVoucherExceptionModal = false;
  }
};

const initialState = {
  items: [],
  bundles: [],
  numberOfItems: 0,
  total: 0,
  subTotal: 0,
  basketId: null,
  voucherCodes: [],
  voucherReduction: 0,
  version: 0,
  outOfStockItemsInSession: [],
  outOfStockItems: [],
  outOfStockBundles: [],
  outOfStockIngredients: [],
  email: null,
  order: null,
  optIn: true,
  delivery: null,
  isDelivery: false,
  deliveryFee: 0,
  serviceFee: 0,
  smallOrderFee: 0,
  discounts: [],
  discountReduction: 0,
  voucherExceptions: [],
  hasSeenVoucherExceptionModal: true,
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setOutOfStock(state, { payload }) {
      updateOutOfStockState(state, payload);
    },
    updateBasket(state, { payload }) {
      updateSignedInBasketUser(state, payload);
      updateOutOfStockState(state, payload);
      commonUpdateBasketState(state, payload, payload.basketId, payload.voucherExceptions);
    },
    clearBasketLocal(state) {
      let orderData = state.order;
      state = { ...initialState };
      state.order = orderData;
    },
    setHasSeenVoucherExceptionModal(state, _) {
      state.hasSeenVoucherExceptionModal = true;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      applicationApi.endpoints.getApplicationState.matchFulfilled,
      (state, { payload }) => {
        if (payload?.basket?.status === 'OK') {
          updateSignedInBasketUser(state, payload.basket.basket);
          updateOutOfStockState(state, payload.basket.basket);
          commonUpdateBasketState(state, payload.basket.basket, payload.basket?.basketId, payload.basket?.voucherExceptions);
        } else if (state.id !== getCookie('BASKET_ID')) {
          return { ...initialState, order: state.order };
        }
      },
    );

    builder.addMatcher(
      basketApi.endpoints.removeOutOfStockItemsFromBasket.matchFulfilled,
      (state, { payload }) => {
        if (payload?.status === 'OK') {
          updateOutOfStockState(state, payload);
          commonUpdateBasketState(state, payload, payload.basket?.basketId);
        }
      },
    );
    [
      customerApi.endpoints.updateGuestDetails.matchFulfilled,
      customerApi.endpoints.guestLogin.matchFulfilled,
      customerApi.endpoints.login.matchFulfilled,
      customerApi.endpoints.validate2FA.matchFulfilled,
      customerApi.endpoints.authenticateBasket.matchFulfilled,
      customerApi.endpoints.registerUser.matchFulfilled,
      customerApi.endpoints.updateCustomer.matchFulfilled,
      customerApi.endpoints.mobileUpdate.matchFulfilled
    ].forEach(match => {
      builder.addMatcher(
        match,
        (state, { payload }) => {
          if (payload?.status === 'OK') {
            updateSignedInBasketUser(state, payload.basket);
          }
        },
      );
    });

    [
      basketApi.endpoints.updateBasketDelivery.matchFulfilled,
      basketApi.endpoints.clearBasketDelivery.matchFulfilled
    ].forEach(match => {
      builder.addMatcher(
        match,
        (state, { payload }) => {
          if (payload?.status === 'OK') {
            commonUpdateBasketState(state, payload, payload.basketId, payload.voucherExceptions);
          }
        },
      );
    });

    builder.addMatcher(
      braintreeApi.endpoints.pay.matchFulfilled,
      (state, { payload }) => {
        state.order = payload.order;
      }
    );

    builder.addMatcher(
      orderApi.endpoints.getOrderForToday.matchFulfilled,
      (state, { payload }) => {
        state.order = payload;
      }
    );

    [
      basketApi.endpoints.addToBasket.matchFulfilled,
      basketApi.endpoints.removeFromBasket.matchFulfilled,
      basketApi.endpoints.updateBasket.matchFulfilled,
      basketApi.endpoints.addBundleToBasket.matchFulfilled,
      basketApi.endpoints.removeBundleFromBasket.matchFulfilled,
      basketApi.endpoints.quickAddItem.matchFulfilled,
    ].forEach((match) => {
      builder.addMatcher(match,
        (state, { payload }) => {
          if (payload?.status === 'OK') {
            commonUpdateBasketState(state, payload, payload.basketId, payload.voucherExceptions);
          }
        });
    });

    [
      basketApi.endpoints.addVoucher.matchFulfilled,
      basketApi.endpoints.removeVoucher.matchFulfilled,
    ].forEach((match) => {
      builder.addMatcher(match,
        (state, { payload }) => {
          if (payload?.status === 'OK') {
            // Don't include voucher exceptions here so that they can be handled by the voucher redeem UI - MV
            commonUpdateBasketState(state, payload, payload.basketId);
          }
        });
    });

    builder.addMatcher(
      customerApi.endpoints.logout.matchFulfilled,
      () => {
        return initialState;
      }
    );

    builder.addMatcher(
      basketApi.endpoints.clearBasket.matchFulfilled,
      () => {
        return initialState;
      }
    );
  },
});

export const {
  setOutOfStock,
  updateBasket,
  clearBasketLocal,
  setHasSeenVoucherExceptionModal
} = basketSlice.actions;
export default basketSlice.reducer;