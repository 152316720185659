import React from 'react';
import DropDown from './DropDown';
import errorIcon from './../img/icons/error.svg';
import DropDownField from './DropDownField';
import closeError from './../img/icons/closeError.svg';

const currentYear = (new Date()).getFullYear();
const days = Array(31).fill(0).map((_, index) => ({value: (index+1).toString(), label: (index+1).toString()}));
const months = Array(12).fill(0).map((_, index) => ({value: (index+1).toString(), label: (index+1).toString()}));
const years = Array(124).fill(0).map((_, index) => ({value: (currentYear-index).toString(), label: (currentYear-index).toString()}));


const DobPicker = ({ valueCallback, value, name, error }) => {
  const d = new Date();
  const isOverEighteen = (dob) => {
    var now = parseInt(d.toISOString().slice(0, 10).replace(/-/g, ''));
    var dobCheck = dob.year * 1000 + dob.month * 100 + dob.day * 1; // Coerces strings to integers
    return now - dobCheck > 180000;
  };

  const changeSelect = (fieldName, value) => {
    valueCallback(name, value, fieldName);
  };

  return (
    <>
      <DropDown
        keyName='dob'
        isInputValid={isOverEighteen(value)}
      >
        <div className="columns half-gap is-mobile">
          <div className="column is-4 pt-0 pb-0">
            <div
              className="control w100"
              style={{ overflow: 'visible' }}
            >
              <DropDownField
                title=''
                name="day"
                icon=''
                value={value.day === 'NaN' ? '' : value.day}
                valueCallback={changeSelect}
                options={[
                  ...days
                ]}
                id='day'
                hasExtraMarginBottom={false}
                error={error}
                hideErrorMessage
                flexibleWidth
                titleExtraIdentifier={'DOB day'}
                placeHolder={'DD'}
                collapse
              />
              {error && !value.day && <i className="icon is-medium is-control">
                <img src={closeError} alt="closeError" />
              </i>}
            </div>
          </div>
          <div className="column is-4 pt-0 pb-0">
            <div
              className="control w100"
              style={{ overflow: 'visible'}}
            >
              <DropDownField
                title=''
                name="month"
                icon=''
                value={value.month === 'NaN' ? '' : value.month}
                valueCallback={changeSelect}
                options={[
                  ...months
                ]}
                id='day'
                hasExtraMarginBottom={false}
                error={error}
                hideErrorMessage
                flexibleWidth
                titleExtraIdentifier={'DOB month'}
                placeHolder={'MM'}
                collapse
              />
              {error && !value.month && <i className="icon is-medium is-control">
                <img src={closeError} alt="closeError" />
              </i>}
            </div>
          </div>
          <div className="column is-4 pt-0 pb-0">
            <div
              className="control w100"
              style={{ overflow: 'visible'}}
            >
              <DropDownField
                title=''
                name="year"
                icon=''
                value={value.year === 'NaN' ? '' : value.year}
                valueCallback={changeSelect}
                options={[
                  ...years
                ]}
                id='day'
                hasExtraMarginBottom={false}
                error={error}
                hideErrorMessage
                flexibleWidth
                titleExtraIdentifier={'DOB year'}
                placeHolder={'YYYY'}
                collapse
              />
              {error && !value.year && <i className="icon is-medium is-control">
                <img src={closeError} alt="closeError" />
              </i>}
            </div>
          </div>
        </div>
      </DropDown>
      {error && (
        <div className="level is-mobile is-family-code" style={{paddingLeft: '6px'}}>
          <div className="level-item is-narrow is-shrinkable is-align-top">
            <i className="icon" >
              <img src={errorIcon} alt="error" />
            </i>
          </div>
          <div className="level-item is-growable is-shrinkable is-justify-content-flex-start is-align-top">
            <div className='error-message'>{error}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default DobPicker;
