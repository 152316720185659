import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { historyMW } from '../../../helpers/routingHelpers';
import { useSendToHome } from '../../../hooks/useSendToHome';
import { useChangeRestaurantMutation } from '../../../services/customer.api';

import exclamationMark from '../../../img/icons/exclamationMark.svg';

const Retry = ({ paymentErrorMessage, setShowSpinnner, setPaymentTaken, errorStatus }) => {
  const history = useHistory();
  const { isDelivery } = useSelector(state => state.basket);
  const [changeRestaurant] = useChangeRestaurantMutation();
  const sendToHome = useSendToHome();
  const retry = () => {
    setShowSpinnner(false);
    setPaymentTaken(false);
    historyMW.replace('/payment', isDelivery, history);
  };

  const getNewRestaurant = async () => {
    setShowSpinnner(false);
    setPaymentTaken(false);
    await changeRestaurant();
    sendToHome();
  };

  return (
    <Layout hideHero section={'is-padless-top is-padless-bottom'}>
      <div style={{ maxWidth: '416px', margin: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 24, height: 588, padding: '40px 24px' }}>
        <img src={exclamationMark} />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <h1 className='title smaller-title' style={{ lineHeight: 1 }}>
            {errorStatus === 'REJECTED' ? 'WE\'RE SORRY!' : <><span style={{ display: 'inline-block' }}>OOPS! SOMETHING</span><span style={{ display: 'inline-block' }}>WENT WRONG</span></>}
          </h1>
          <p>{paymentErrorMessage}</p>
        </div>
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {errorStatus === 'REJECTED'
            ? (<div id='' onClick={getNewRestaurant} className="btn btn-primary w10" style={{ margin: 'auto' }}>Change restaurant</div>)
            : (<div id='continueButton' onClick={retry} className="btn btn-primary w10" style={{ margin: 'auto' }}>Try again</div>)
          }
        </div>
      </div>
    </Layout>
  );
};

export default Retry;
