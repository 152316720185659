import React, { Fragment, useEffect, useState } from 'react';
import LoyaltyTicket from './LoyaltyTicket';
import LoyaltyText from './LoyaltyText';
import { getRewardImage } from '../../helpers/loyaltyHelpers';
import chevronLeft from '../../img/icons/ChevronLeft.svg';
import chevronRight from '../../img/icons/ChevronRight.svg';
import chevronLeftWhite from '../../img/icons/ChevronLeftWhite.svg';
import chevronRightWhite from '../../img/icons/ChevronRightWhite.svg';
import { useSelector } from 'react-redux';

const LoyaltyTicketCard = ({
  sectionIndex,
  isDisabled,
  onTicketClick,
  subHeading,
  data: {
    title,
    designOfTile,
    missingText,
    subSections,
    readOnly,
    selectionComplete,
    tags,
    thirdPartyRewardTypeId
  },
  tierName,
  index,
  setActiveIndex,
  colourInverted,
  cardCount,
  indicator,
  cardDisplay,
  updating,
  rewardsInfo
}) => {
  const rewardImage = getRewardImage(designOfTile);
  const { inApp } = useSelector(state => state.session);
  const [prevCompletionState, setPrevCompletionState] = useState(selectionComplete);
  const rewardInfo = rewardsInfo?.find(r => r.rewardDetails?.thirdPartyRewardTypeId === thirdPartyRewardTypeId);
  const tier = rewardInfo?.tierName ? rewardInfo.tierName === 'SignUp' ? 'Club Member' : rewardInfo.tierName : tierName === 'SignUp' ? 'Club Member' : tierName;

  let rewardImageAsset = null;
  try {
    rewardImageAsset = require('./../../img/loyalty/wizard/' + rewardImage.image + '.svg');
  }
  catch {
    console.warn('Unable to load ticket card image');
  }

  useEffect(() => {
    const isCompleted = subSections.flatMap(el => el.offers).every(el => el.groupComplete);
    if (isCompleted && isCompleted !== prevCompletionState && subSections?.length) {
      setTimeout(() => setActiveIndex(index + 1), 400)
    }
    setPrevCompletionState(isCompleted);
  }, [subSections])

  return (
    <>
      <div className="card-container">
        <div style={{ borderRadius: 16, backgroundColor: 'rgba(255,255,255)' }}>
          {
            inApp &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', paddingTop: '1rem', opacity: isDisabled && inApp && 0.56 }}>
              <img
                onClick={() => {
                  setActiveIndex(index - 1);
                }}
                style={{ cursor: 'pointer', opacity: index === 0 ? 0 : 1, width: 24 }}
                src={chevronLeft}

              />

              <p className='dm-sans'>{index + 1}/{indicator === 0 ? 1 : indicator}</p>

              <img
                onClick={() => {
                  setActiveIndex(index + 1);
                }}
                src={chevronRight}
                style={{ cursor: 'pointer', opacity: index === (cardCount - cardDisplay) || cardCount === 1 ? 0 : 1, width: 24 }}
              />
            </div>
          }
          <div className="voucher-card card-header-top" style={{ opacity: isDisabled && inApp && 0.56 }}>
            {title && <div className="content title-content">
              <h3 className="title is-3" style={{ wordBreak: 'keep-all' }}>
                <LoyaltyText text={title} />
              </h3>
              {tags.includes('BOOSTEROFFER')
                ? <p style={{ fontSize: '0.75rem', WebkitLineClamp: 3, marginBottom: 0 }}>Exclusive Offer</p>
                : <p style={{ fontSize: '0.75rem', WebkitLineClamp: 3, marginBottom: 0 }}>{tier} Reward</p>
              }
              {
                subHeading && <p style={{ fontSize: '0.75rem', WebkitLineClamp: 3 }}>{subHeading}</p>
              }
            </div>}
            {rewardImageAsset && <img src={rewardImageAsset} className='apply-rewards-image' />}
          </div>
          {
            subSections?.length ?
              <div style={{ display: 'flex', flexDirection: 'column', maxHeight: inApp ? '30vh' : null, overflowY: 'auto' }}>
                {subSections?.map((subSection, i) => {
                  return (<Fragment key={`subsection=${i}`}>
                    <div className={`voucher-card card-header-description ${subSection.displayTitle ? '' : 'remove-divider'}`}>
                      {subSection.displayTitle && subSection.title}
                      {inApp && title == 'Free Delivery' && <p>Reward will be applied at checkout</p>}
                    </div>

                    {subSection.offers.map((offer, i) =>
                      <LoyaltyTicket
                        key={`LoyaltyTicket-${i}`}
                        sectionIndex={sectionIndex}
                        isDisabled={isDisabled || updating || (offer.selected && !offer.selectedByThisUser) || offer.disabled || readOnly}
                        onTicketClick={onTicketClick}
                        offer={offer}
                        lastItem={(subSection.offers.length - 1) == i}
                        tierName={tierName}
                        inApp={inApp}
                      />)}
                  </Fragment>);
                }
                )}
              </div>
              :
              <></>
          }
          {subSections?.length === 0 &&
            <>
              <div className="card-parent">
                <div id="div4-id" className={'voucher-card ticket-container card-no-order card1'}>
                  <div className="ticket-container-inner empty">
                    <div className="card-content">
                      <p className='dm-sans'>{missingText}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>}
        </div>
      </div>
    </>
  );
};

export default LoyaltyTicketCard;
