import React, { useState } from 'react';
import RewardCarousel from './RewardCarousel';
import RewardTicket from './RewardTicket';
import { LoyaltyModalWrapper } from '../../helpers/modalHelpers';
import { useGetAllRewardsQuery } from '../../services/customer.api';
import { RewardPickerTester } from './RewardPickerTester';
const createReward = (id, designOfTile, nameOfColour, colourOfTilenbw, lock) => {
  const theme = colourOfTilenbw === 'saxophone' || colourOfTilenbw === 'midnight' ? 'White' : 'Black';

  return {
    thirdPartyRewardTypeId: id,
    validTo: '2026-08-26T23:59:00+01:00',
    validFrom: '2021-08-26T00:00:00+01:00',
    name: 'Sign Up Doughballs',
    isLocked: lock,
    rewardDetails: {
      colourOfTilenbw,
      colourOfTilenbwConverted: colourOfTilenbw,
      designOfTile,
      nbwRewardTheme: theme,
      excludeFrom10Minimum: false,
      expiryDateToDisplay: true,
      newReward: false,
      priority: 2,
      productCategory: 'Starters & Sides',
      rewardCardDescription: 'Expiry should display not this',
      rewardDescription: null,
      rewardDetailPageDescription:
        "Enjoy a portion of our delicious, hot & fluffy Dough Balls 'PizzaExpress' for free when you spend £10 or more in one of our pizzerias.",
      rewardTitle: designOfTile + (nameOfColour ? ' (' + nameOfColour + ')' : ''),
      tcs: "There are a few dos and don’ts. Check them out in the terms below.\r\n1. Valid Monday to Sunday for up to 6 weeks from the day of issuance of your reward.\r\n2. This reward gives you one free portion of Dough Balls ‘PizzaExpress’* when you download the PizzaExpress Club app and register. Minimum spend of £10 required.\r\n*Can't eat gluten or dairy? Ask for our gluten-free or vegan Dough Balls instead.\r\n3. Please note that Dough Balls Doppio, Snowball Dough Balls Doppio or any other Dough Balls dish that might be available on our menu at the time of your visit are not included.\r\n4. Only valid when dining in. You can't use it on collection orders, or for delivery, including Deliveroo, Just Eat or Uber Eat orders.\r\n5. This reward is limited to one portion of Free Dough Balls ‘PizzaExpress’ and can only be used once. Valid until stock lasts. Sadly, we can't exchange your code for cash, just great value food.\r\n6. You can’t pick your Dough Balls from our Piccolo menu, but little ones are still welcome to enjoy the Piccolo menu while you make the most of this reward.\r\n7. This reward can't be used in conjunction with any other partner, for example Tesco or UNiDAYS, or PizzaExpress promotional offers or set menus, except for our Goodwill or How Did We Dough survey vouchers.\r\n8. This reward can be used in conjunction with other PizzaExpress Club rewards unless stated otherwise. You can also use it alongside other PizzaExpress Club member’s free rewards if you’re splitting the bill.\r\n9. To claim your reward, simply apply it to your bill within the app.\r\n10. Brought to you by: PizzaExpress, Hunton House, Highbridge Estate, Oxford Road, Uxbridge, UB8 1LX.",
      validityText:
        'Available Monday - Sunday until {{VOUCHER_EXPIRY}} when you dine in and spend at least £10. For all details, make sure to read the full ***Terms and Conditions***',
      lockedValidityTextExpiryDate: false,
      zonalVariableDiscountCode: 306,
      rewardBundle: false,
      whitelistedAccounts: 'sam.head@pizzaxpress.com andyandsam12@btinternet.com *@methodworx.com *petest@sharklasers.com',
      displayInHomepageCarousel: true,
      sectionPriority: 300,
      showTierName: true,
      rewardDetailPageHeaderCopy: null,
      rewardDetailPageExternalCtaLink: null,
      rewardDetailPageCtaText: null,
      rewardDetailPageInternalCtaLink: null,
      displayInRewardWizard: true,
      displayInSeeMyRewards: true,
      displayInCongratulationsModal: true,
      displayInCheckedInCarousel: true,
    },
    discountPercentage: 0,
    type: 'Standard',
    crmBoosterCard: null,
  };
};

const designOfTiles = [
  'Dessert',
  'Doughballs',
  'Cocktail',
  'Hot Drink',
  'Piccolo',
  'Kids',
  'Cookbook',
  'Jazz',
  'Pizza',
  'Generic Birthday',
  'Side/Salad',
  'Soft Drink/Water',
  'Soft Drink',
  'Water',
  'Beer',
  'Swap',
  'Glass Of Wine',
  'Wine Bottle',
  'Goodwill/Celebration Pink',
  'Celebration Blue',
  'Goodwill/Celebration White',
  'Celebration White',
  'Goodwill/Celebration Orange',
  'Celebration Red',
  'Booster 1',
  'Booster 2',
  'Booster 3',
  'Generic Image 1',
  'Generic Image 2',
  'Pizza Veg',
  'Duo Pizza 1',
  'Duo Pizza 2',
  'Valentines',
];

const localRewards = designOfTiles.map((d) => ([
  createReward(1, d, 'Saxophone Gold', 'saxophone', true),
  createReward(1, d, 'Saxophone Gold', 'saxophone'),
  createReward(1, d, 'Hi-Hat Yellow', 'hiHat', true),
  createReward(1, d, 'Hi-Hat Yellow', 'hiHat'),
  createReward(1, d, 'Hi-Hat Pale Yellow', 'hiHat20', true),
  createReward(1, d, 'Hi-Hat Pale Yellow', 'hiHat20'),
  createReward(1, d, 'Moonlight Blue', 'moonlight80', true),
  createReward(1, d, 'Moonlight Blue', 'moonlight80'),
  createReward(1, d, 'Rosé', 'rose', true),
  createReward(1, d, 'Rosé', 'rose'),
  createReward(1, d, 'Loyalty Gold', 'Gold', true),
  createReward(1, d, 'Loyalty Gold', 'Gold'),
  createReward(1, d, 'Loyalty Silver', 'Silver', true),
  createReward(1, d, 'Loyalty Silver', 'Silver'),
  createReward(1, d, 'Loyalty Bronze', 'Bronze', true),
  createReward(1, d, 'Loyalty Bronze', 'Bronze'),
  createReward(1, d, 'Midnight Blue', 'midnight', true),
  createReward(1, d, 'Midnight Blue', 'midnight'),
  createReward(2, d, 'Saxophone Gold', 'saxophone', true),
  createReward(2, d, 'Saxophone Gold', 'saxophone'),
  createReward(2, d, 'Hi-Hat Yellow', 'hiHat', true),
  createReward(2, d, 'Hi-Hat Yellow', 'hiHat'),
  createReward(2, d, 'Hi-Hat Pale Yellow', 'hiHat20', true),
  createReward(2, d, 'Hi-Hat Pale Yellow', 'hiHat20'),
  createReward(2, d, 'Moonlight Blue', 'moonlight80', true),
  createReward(2, d, 'Moonlight Blue', 'moonlight80'),
  createReward(2, d, 'Rosé', 'rose', true),
  createReward(2, d, 'Rosé', 'rose'),
  createReward(2, d, 'Loyalty Gold', 'Gold', true),
  createReward(2, d, 'Loyalty Gold', 'Gold'),
  createReward(2, d, 'Loyalty Silver', 'Silver', true),
  createReward(2, d, 'Loyalty Silver', 'Silver'),
  createReward(2, d, 'Loyalty Bronze', 'Bronze', true),
  createReward(2, d, 'Loyalty Bronze', 'Bronze'),
  createReward(2, d, 'Midnight Blue', 'midnight', true),
  createReward(2, d, 'Midnight Blue', 'midnight')
])
)

export const CompareRewards = () => {
  const [loyaltyModalOpen, setLoyaltyModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState(false);
  const [isLocal, setIsLocal] = useState(true);
  const [showRewardPicker, setShowRewardPicker] = useState();
  const { data } = useGetAllRewardsQuery();
  const [tier, setTier] = useState('gold');

  const handleClose = () => {
    setSelectedReward({});
    setLoyaltyModalOpen(false);
  };

  const handleClickVoucher = ({ reward, loyaltyRewardImage }) => {
    setSelectedReward({ reward, loyaltyRewardImage: loyaltyRewardImage });
    setLoyaltyModalOpen(true);
  };

  if (!showRewardPicker) return (
    <div style={{ padding: '5vh 5vw', overflowY: 'scroll', maxHeight: '80vh' }}>
      <div style={{ display: 'flex', width: '100', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10, marginBottom: 20 }}>
        <button className='btn btn-primary' onClick={() => setIsLocal(!isLocal)}>
          Toggle API
          <div
            style={{
              display: 'flex',
              justifyContent: isLocal ? 'flex-start' : 'flex-end',
              width: 30,
              background: '#444',
              marginLeft: 15,
              borderRadius: 100
            }}>
            <div
              style={{
                height: 15,
                width: 15,
                borderRadius: 100,
                backgroundColor: isLocal ? 'crimson' : 'lightgreen'
              }}></div></div>
        </button>
        <button className='btn btn-primary' onClick={() => setShowRewardPicker(true)}>Reward Picker</button>
      </div>
      <LoyaltyModalWrapper
        show={loyaltyModalOpen}
        onClose={handleClose}
        reward={selectedReward.reward}
        loyaltyRewardImage={selectedReward.loyaltyRewardImage}
      />
      {
        isLocal ?
          localRewards.map((rm, idx) =>
            <RewardCarousel key={idx}>
              {
                rm.map((r, i) =>
                  <RewardTicket
                    handleModal={handleClickVoucher}
                    key={`reward-${i}`}
                    colourOfTile={r.rewardDetails.colourOfTilenbw}
                    designOfTile={r.rewardDetails.designOfTile}
                    reward={r}
                    index={i}
                    tierName={'Gold'} />
                )
              }
            </RewardCarousel>
          )
          :
          <RewardCarousel>
            {
              data?.map((r, i) =>
                <RewardTicket
                  handleModal={handleClickVoucher}
                  key={`reward-${i}`}
                  colourOfTile={r.rewardDetails.colourOfTilenbw}
                  designOfTile={r.rewardDetails.designOfTile}
                  reward={r}
                  index={i}
                  tierName={'Gold'} />)
            }
          </RewardCarousel>
      }
    </div>
  );
  return <div style={{ padding: '5vh 0', overflowY: 'scroll', maxHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: 20, flexWrap: 'wrap', gap: 10, padding: '0 5vw' }}>
      <div style={{display: 'flex', gap: 20, height: 25}}>
        <label>Tier:</label>
        <select onChange={e => setTier(e.target.value)} style={{borderRadius: 100, padding: '0 10px'}}>
          <option value='gold'>Gold</option>
          <option value='silver'>Silver</option>
          <option value='bronze'>Bronze</option>
          <option value='signup'>SignUp</option>
        </select>
      </div>
      <button className='btn btn-primary' onClick={() => setShowRewardPicker(false)}>Cards</button>
    </div>
    <RewardPickerTester tier={tier} data={{
      sections: data?.map(d => {
        return {
          title: d.rewardDetails.rewardTitle,
          subSections: [],
          missingText: 'Oh, darn.',
          maxSelectionForReward: 2,
          ...d.rewardDetails
        }
      }) ?? []
    }} />
  </div>
};
